import { type ComponentProps, type FC } from "react";
import { useTranslation } from "react-i18next";

import { TradingTablesPopoverContent } from "../../ui/popover-content";
import { ModifyOrderStopLossContainer } from "./container";

type Props = ComponentProps<typeof ModifyOrderStopLossContainer>;

const StopLossPopoverContent: FC<Props> = props => {
  const { t } = useTranslation();

  return (
    <TradingTablesPopoverContent
      title={t("terminal.orders.stop-loss.title")!}
      tooltip={t("terminal.orders.stop-loss.description")}
    >
      <ModifyOrderStopLossContainer {...props} />
    </TradingTablesPopoverContent>
  );
};

export { StopLossPopoverContent };
