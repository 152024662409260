import type { FC } from "react";

import { useTerminalAccountSummary } from "./context";
import { AccountSummary } from "./summary";

const AccountSummaryContainer: FC = () => {
  const { currency, equity, marginLevel, currencyDecimalScale, marginFree, margin } = useTerminalAccountSummary();

  return (
    <AccountSummary
      currencyDecimalScale={currencyDecimalScale}
      marginLevel={marginLevel}
      currency={currency}
      equity={equity}
      marginFree={marginFree}
      margin={margin}
    />
  );
};

export { AccountSummaryContainer };
