import dayjs from "dayjs";
import { type FC, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";

import { NumberFormat } from "@/app/components";
import { Leverage } from "@/entities/accounts/leverage";
import type { DayOfWeek, TradingServerSymbolType } from "@/services/openapi";
import { DataList } from "@/shared/ui";
import { getDayOfWeek } from "@/utils/dates";

import {
  calculateLeverage,
  calculatePipsSpread,
  calculatePipValue,
  pipsSpreadDecimalScale,
} from "../../helpers/formulas";
import { SymbolInfoBlock } from "./ui/block";

type Props = {
  contractSize: number;
  instrumentType: TradingServerSymbolType;
  marginRateInitialMarketBuy: number;
  leverage: number;
  unit: string | null;
  volumeMin: number;
  volumeMax: number;
  swapTriple: DayOfWeek;
  swapLong: number;
  swapShort: number;
  priceBid: number | null;
  priceAsk: number | null;
  priceDecimalScale: number;
  volumeDecimalScale: number;
  currencyDecimalScale: number;
  accountCurrency: string;
};

const SymbolInfoDetailsBlock: FC<Props> = ({
  contractSize,
  unit,
  leverage,
  instrumentType,
  marginRateInitialMarketBuy,
  volumeMin,
  volumeMax,
  swapTriple,
  swapLong,
  swapShort,
  priceAsk,
  priceBid,
  priceDecimalScale,
  volumeDecimalScale,
  accountCurrency,
  currencyDecimalScale,
}) => {
  const { t } = useTranslation();

  const spread = useMemo(
    () => calculatePipsSpread({ ask: priceAsk || 0, bid: priceBid || 0, priceDecimalScale }),
    [priceAsk, priceBid, priceDecimalScale],
  );

  return (
    <SymbolInfoBlock>
      <DataList>
        <DataList.Item
          label={<DataList.Label>{t("terminal.symbol-info.lot-size")}</DataList.Label>}
          value={
            <DataList.Value>
              <NumberFormat value={contractSize} currency={unit ? unit : void 0} />
            </DataList.Value>
          }
        />
        <DataList.Item
          label={<DataList.Label>{t("terminal.symbol-info.spread")}</DataList.Label>}
          value={
            <DataList.Value>
              <Trans
                i18nKey="terminal.symbol-info.pip"
                values={{ count: spread }}
                components={{
                  value: <NumberFormat value={spread} decimalScale={pipsSpreadDecimalScale} />,
                }}
              />
            </DataList.Value>
          }
        />
        <DataList.Item
          label={<DataList.Label>{t("terminal.symbol-info.pip-value")}</DataList.Label>}
          value={
            <DataList.Value>
              <NumberFormat
                value={useMemo(
                  () => calculatePipValue({ contractSize, priceDecimalScale, volumeLots: 1 }),
                  [contractSize, priceDecimalScale],
                )}
                currency={accountCurrency}
                decimalScale={currencyDecimalScale}
              />
            </DataList.Value>
          }
        />
        <DataList.Item
          label={<DataList.Label>{t("terminal.symbol-info.swap")}</DataList.Label>}
          value={
            <DataList.Value>
              <Trans
                i18nKey="terminal.symbol-info.pip"
                values={{ count: swapLong }}
                components={{
                  value: <NumberFormat value={swapLong} decimalScale={pipsSpreadDecimalScale} />,
                }}
              />
              /
              <Trans
                i18nKey="terminal.symbol-info.pip"
                values={{ count: swapShort }}
                components={{
                  value: <NumberFormat value={swapShort} decimalScale={pipsSpreadDecimalScale} />,
                }}
              />
            </DataList.Value>
          }
        />
        <DataList.Item
          label={<DataList.Label>{t("terminal.symbol-info.3-day-swap")}</DataList.Label>}
          value={
            <DataList.Value className="capitalize">
              {useMemo(() => dayjs().day(getDayOfWeek(swapTriple)).format("dddd"), [swapTriple])}
            </DataList.Value>
          }
        />
        <DataList.Item
          label={<DataList.Label>{t("terminal.symbol-info.leverage")}</DataList.Label>}
          value={
            <DataList.Value>
              <Leverage
                leverage={useMemo(
                  () => calculateLeverage({ leverage, instrumentType, marginRateInitialMarketBuy }),
                  [instrumentType, marginRateInitialMarketBuy, leverage],
                )}
              />
            </DataList.Value>
          }
        />
        <DataList.Item
          label={<DataList.Label>{t("terminal.symbol-info.min-volume")}</DataList.Label>}
          value={
            <DataList.Value>
              <Trans
                i18nKey="terminal.symbol-info.lot"
                values={{ count: volumeMin }}
                components={{
                  value: <NumberFormat value={volumeMin} decimalScale={volumeDecimalScale} />,
                }}
              />
            </DataList.Value>
          }
        />
        <DataList.Item
          label={<DataList.Label>{t("terminal.symbol-info.max-volume")}</DataList.Label>}
          value={
            <DataList.Value>
              <Trans
                i18nKey="terminal.symbol-info.lot"
                values={{ count: volumeMax }}
                components={{
                  value: <NumberFormat value={volumeMax} decimalScale={volumeDecimalScale} />,
                }}
              />
            </DataList.Value>
          }
        />
      </DataList>
    </SymbolInfoBlock>
  );
};

export { SymbolInfoDetailsBlock };
