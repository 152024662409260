import { type FC, type ReactNode } from "react";

import { Text, type TextColor } from "@/shared/ui";

type Props = {
  title: ReactNode;
  subText?: ReactNode;
  subTextColor?: TextColor;
  children?: ReactNode;
  endBlock?: ReactNode;
};

const AccountDetailsInfoBlock: FC<Props> = ({ title, subText, subTextColor = "tertiary", children, endBlock }) => {
  return (
    <div className="rounded-[16px] border border-card-border bg-card-bg-accent p-4">
      <div className="flex items-start justify-between">
        <div className="flex flex-col gap-1">
          <Text as="h5" variant="S / Regular" color="secondary">
            {title}
          </Text>
          <Text variant="M / Medium" color={subTextColor}>
            {subText}
          </Text>
        </div>
        {endBlock}
      </div>
      {children}
    </div>
  );
};

export { AccountDetailsInfoBlock };
