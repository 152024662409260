import type { FC } from "react";
import { memo } from "react";

import { IconArrowDown, IconArrowUp } from "@/domains/icons";
import { getOrderTypeText, getTypeColor } from "@/features/terminal/orders/helpers";
import { useTranslation } from "@/hooks/translator.hook";
import { TerminalDealType } from "@/services/openapi";
import { cn } from "@/shared/styles";

type Props = {
  type: TerminalDealType;
};

const OrderTypeFormat: FC<Props> = ({ type }) => {
  const { t } = useTranslation();

  const isColorPositive = getTypeColor({ type }) === "positive";

  return (
    <div className="flex items-center gap-1">
      <div className={cn("*:size-4", isColorPositive ? "text-positive-text" : "text-negative-text")}>
        {isColorPositive ? <IconArrowUp /> : <IconArrowDown />}
      </div>
      {getOrderTypeText({ t, type })}
    </div>
  );
};

const Component = memo(OrderTypeFormat);

export { Component as OrderTypeFormat };
