import type { FC } from "react";
import { memo } from "react";
import { useTranslation } from "react-i18next";

import { type TerminalSymbolWidgetBuzzing, TradingCentralBuzzingType } from "@/services/openapi";
import { Text } from "@/shared/ui";

import { WidgetBlock } from "../../../widgets/block";
import { Gauge } from "./gauge";

type Props = {
  buzzing: TerminalSymbolWidgetBuzzing;
};

const NewsVolumeWidget: FC<Props> = ({ buzzing }) => {
  const { t } = useTranslation();

  const { mentionsVolume24, trend } = buzzing;

  return (
    <WidgetBlock
      title={t("terminal.insights.widgets.news-volume.title")}
      description={t("terminal.insights.widgets.news-volume.description")}
    >
      <div className="flex items-center justify-between gap-4">
        <div className="flex flex-col gap-2">
          <Text variant="M Compact / Medium" color="primary">
            {trend === TradingCentralBuzzingType.Buzzing && t("terminal.insights.widgets.news-volume.buzzing")}
            {trend === TradingCentralBuzzingType.AboveAverage &&
              t("terminal.insights.widgets.news-volume.above-average")}
            {trend === TradingCentralBuzzingType.Average && t("terminal.insights.widgets.news-volume.average")}
            {trend === TradingCentralBuzzingType.BelowAverage &&
              t("terminal.insights.widgets.news-volume.below-average")}
          </Text>
          <Text variant="S / Regular" color="secondary">
            {t("terminal.insights.widgets.news-volume.mentions", { number: mentionsVolume24 })}
          </Text>
        </div>
        <Gauge buzzingTrend={trend!} />
      </div>
    </WidgetBlock>
  );
};

const Component = memo(NewsVolumeWidget);

export { Component as NewsVolumeWidget };
