import type { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { useDisclosure } from "@/hooks/disclosure.hook";
import { cn } from "@/shared/styles";
import { Popover, UnstyledButton } from "@/shared/ui";

type Props = {
  value: ReactNode;
  children: (onClose: () => void) => ReactNode;
};

const ModifyOrderButton: FC<Props> = ({ value, children }) => {
  const { t } = useTranslation();

  const [opened, { onOpenChange, close }] = useDisclosure();

  return (
    <Popover open={opened} onOpenChange={onOpenChange}>
      <Popover.Trigger asChild>
        <UnstyledButton
          className={cn(
            "typography-S-Underlined text-contrast-secondary outline-none transition-colors hover:text-contrast-primary",
            (opened || value) && "text-contrast-primary",
          )}
        >
          {value ? value : t("button.edit")}
        </UnstyledButton>
      </Popover.Trigger>
      {children(close)}
    </Popover>
  );
};

export { ModifyOrderButton };
