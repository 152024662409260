import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { NumberFormat } from "@/app/components";
import { IconArrowLeft } from "@/domains/icons";
import { Button, Text } from "@/shared/ui";

import { useLayoutContext } from "../layout/context";
import { useCurrentSymbolContext } from "../symbol-info/current-symbol-context";
import { usePriceChange } from "../symbols/helpers";
import { SymbolIcon } from "../symbols/icon";
import { SymbolPriceChange } from "../symbols/price-change";

const MobileSymbolBlock: FC = () => {
  const { t } = useTranslation();

  const { symbolInfo } = useCurrentSymbolContext();

  const { openWatchlist, openInsightsDialog } = useLayoutContext();

  const priceChange = usePriceChange({ bid: symbolInfo.priceBid, priceLast24H: symbolInfo.priceLast24H });

  return (
    <div className="sticky top-0 z-10 px-4 pb-2 pt-5 backdrop-blur">
      <div className="flex items-center justify-between gap-2">
        <div className="flex gap-4">
          <button type="button" className="flex" onClick={() => openWatchlist()}>
            <IconArrowLeft />
          </button>
          <SymbolIcon size="xl" symbol={symbolInfo.symbol!} />
          <div className="flex flex-col gap-1">
            <Text variant="M Compact / Medium" color="primary">
              {symbolInfo.symbol}
            </Text>
            <div className="flex items-center gap-2">
              <Text variant="S / Regular" color="secondary">
                <NumberFormat value={symbolInfo.priceBid || 0} decimalScale={symbolInfo.digits} />
              </Text>
              {priceChange && (
                <SymbolPriceChange priceChange={priceChange}>
                  {({ value, icon }) => (
                    <div className="flex items-center">
                      {icon}
                      <Text color={priceChange.color || "secondary"} variant="S / Regular">
                        {value}
                      </Text>
                    </div>
                  )}
                </SymbolPriceChange>
              )}
            </div>
          </div>
        </div>
        <Button
          variant="tertiary"
          size="sm"
          onClick={openInsightsDialog}
          startSection={
            // FIXME:
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.33322 8.66637C7.33322 8.29846 7.63147 8.00021 7.99937 8.00021C8.36728 8.00021 8.66553 8.29846 8.66553 8.66637C8.66553 9.03427 8.36728 9.33252 7.99937 9.33252C7.63147 9.33252 7.33322 9.03427 7.33322 8.66637Z"
                fill="currentColor"
              />
              <path
                d="M7.33317 6.66716C7.33335 7.03534 7.63197 7.33368 8.00016 7.3335C8.36835 7.33332 8.66668 7.03469 8.6665 6.6665L8.66553 4.66654C8.66535 4.29835 8.36672 4.00002 7.99853 4.0002C7.63034 4.00038 7.33201 4.299 7.33219 4.66719L7.33317 6.66716Z"
                fill="currentColor"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.6665 11.3066C10.6665 11.3066 10.6679 11.3025 10.6707 11.2979C10.677 11.2876 10.6901 11.2729 10.7111 11.2604C12.2792 10.3332 13.3332 8.62346 13.3332 6.66683C13.3332 3.72131 10.9454 1.3335 7.99984 1.3335C5.05432 1.3335 2.6665 3.72131 2.6665 6.66683C2.6665 8.62346 3.72052 10.3332 5.28855 11.2604C5.30961 11.2729 5.32264 11.2876 5.32895 11.2979C5.33178 11.3025 5.33317 11.3066 5.33317 11.3066V14.0002C5.33317 14.7365 5.93012 15.3335 6.6665 15.3335H9.33317C10.0695 15.3335 10.6665 14.7365 10.6665 14.0002V11.3066ZM7.99984 2.66683C5.7907 2.66683 3.99984 4.45769 3.99984 6.66683C3.99984 8.13288 4.78816 9.41551 5.96723 10.1128C6.36204 10.3462 6.6665 10.7803 6.6665 11.3058V11.3335H9.33317V11.3058C9.33317 10.7803 9.63764 10.3462 10.0324 10.1128C11.2115 9.41552 11.9998 8.13288 11.9998 6.66683C11.9998 4.45769 10.209 2.66683 7.99984 2.66683ZM9.33317 12.6668H6.6665V14.0002H9.33317V12.6668Z"
                fill="currentColor"
              />
            </svg>
          }
        >
          {t("terminal.insights.title")}
        </Button>
      </div>
    </div>
  );
};

export { MobileSymbolBlock };
