import { cva } from "class-variance-authority";
import { type FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { cn } from "@/shared/styles";
import { Text, ToggleGroup } from "@/shared/ui";

import { InsightsContainer } from "../../insights/container";
import { useCurrentSymbolContext } from "../../symbol-info/current-symbol-context";
import { SymbolInfoDialogButton } from "../../symbol-info/dialog/button";
import { PlaceOrderContainer } from "../container";
import { TerminalPlaceOrderProvider } from "../context";
import { TradingUnavailableBlock } from "../trading-unavailable/block";

const contentStyles = cva("min-h-0 grow overflow-auto pt-3 scrollbar-hide")();

const PlaceOrderTabs: FC = () => {
  const { t } = useTranslation();

  const [tab, setTab] = useState<"trade" | "insights">("trade");

  const { isTradingAvailable, symbolInfo } = useCurrentSymbolContext();

  return (
    <>
      <div className="flex flex-col gap-1 px-4 pt-2">
        <div className="flex items-center justify-between gap-2">
          <Text as="h3" color="primary" variant="M / Medium">
            {symbolInfo.name}
          </Text>
          <SymbolInfoDialogButton />
        </div>
        <ToggleGroup value={tab} onValueChange={setTab}>
          <ToggleGroup.Item value="trade">{t("terminal.place-order.tabs.trade")}</ToggleGroup.Item>
          <ToggleGroup.Item value="insights">{t("terminal.place-order.tabs.insights")}</ToggleGroup.Item>
        </ToggleGroup>
      </div>
      {tab === "trade" && (
        <div className={contentStyles}>
          {isTradingAvailable ? (
            <TerminalPlaceOrderProvider>
              <PlaceOrderContainer />
            </TerminalPlaceOrderProvider>
          ) : (
            <TradingUnavailableBlock />
          )}
        </div>
      )}
      {tab === "insights" && (
        <div className={cn(contentStyles, "px-4 pb-4")}>
          <InsightsContainer />
        </div>
      )}
    </>
  );
};

export { PlaceOrderTabs };
