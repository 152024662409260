import { cva } from "class-variance-authority";
import dayjs from "dayjs";
import { type FC } from "react";

import type { TerminalTradingCentralArticle } from "@/services/openapi";
import { Text, UnstyledButton } from "@/shared/ui";

import { NewsDialog } from "./dialog";
import { ArticleImage } from "./image";

const topicStyles = cva("typography-XS-Regular rounded-[16px] bg-card-bg-hover px-3 py-1 text-contrast-primary")();

type Props = {
  article: TerminalTradingCentralArticle;
};

const Article: FC<Props> = ({ article }) => {
  const { title, publisher, date, images, topics } = article;

  return (
    <div className="flex gap-3">
      <ArticleImage images={images!} title={title!} />
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <Text as="h5" variant="XS / Regular" color="secondary">
            {publisher} • {dayjs().to(dayjs(date))}
          </Text>
          <NewsDialog
            article={article}
            trigger={
              <UnstyledButton className="typography-M-Compact-Medium line-clamp-3 text-start text-contrast-primary outline-none transition-colors hover:text-accent-text focus-visible:text-accent-text">
                {title}
              </UnstyledButton>
            }
          />
        </div>
        {topics!.length > 0 && (
          <div className="flex flex-wrap gap-2">
            {topics!.slice(0, 3).map(topic => (
              <div key={topic} className={topicStyles}>
                {topic}
              </div>
            ))}
            {topics!.length > 3 && <div className={topicStyles}>+{topics!.length - 3}</div>}
          </div>
        )}
      </div>
    </div>
  );
};

export { Article };
