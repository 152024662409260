import { useStore } from "@nanostores/react";
import { type FC } from "react";
import { Trans } from "react-i18next";

import { IconTimeRound } from "@/domains/icons";
import { InfoBlock } from "@/shared/ui";

import { $serverTime } from "../../helpers/server-time";
import { useTradingUnavailableInfo } from "../../helpers/symbols";

type Props = {
  periodDateEnd: string | null;
};

const WatchlistTradingUnavailableAlert: FC<Props> = ({ periodDateEnd }) => {
  const currentDate = useStore($serverTime);

  const { date, showStatus, time, timeLeftMinutes, timezone } = useTradingUnavailableInfo({
    periodDateEnd,
    currentDate: currentDate!,
  });

  return (
    <InfoBlock
      variant="warning"
      icon={<IconTimeRound />}
      text={
        <>
          {showStatus === "date" && (
            <Trans
              i18nKey="terminal.watchlist.trading-unavailable.date"
              values={{ date, time, timezone }}
              components={{
                strong: <InfoBlock.Strong />,
              }}
            />
          )}
          {showStatus === "today" && (
            <Trans
              i18nKey="terminal.watchlist.trading-unavailable.today-at"
              values={{ time, timezone }}
              components={{
                strong: <InfoBlock.Strong />,
              }}
            />
          )}
          {showStatus === "time" && (
            <Trans
              i18nKey="terminal.watchlist.trading-unavailable.time"
              values={{ time: timeLeftMinutes }}
              components={{
                strong: <InfoBlock.Strong />,
              }}
            />
          )}
        </>
      }
    />
  );
};

export { WatchlistTradingUnavailableAlert };
