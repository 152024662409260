import { type ComponentProps, type FC, useCallback } from "react";

import { useSymbolsContext } from "@/features/terminal/contexts/symbols.context";
import { getInputNumberValue } from "@/features/terminal/helpers/formatting";
import { TerminalDealType } from "@/services/openapi";
import { useModifyTerminalOrderMutation } from "@/state/server/terminal";

import { ModifyTakeProfitForm } from "./form";

type Props = {
  accountId: string;
  orderId: number;
  priceDecimalScale: number;
  takeProfit: number | undefined;
  stopLoss: number | undefined;
  ask: number;
  bid: number;
  orderType: TerminalDealType;
  currencyDecimalScale: number;
  baseCurrency: string;
  quoteCurrency: string;
  contractSize: number;
  volume: number;
  currency: string;
  openPrice: number;
  onClose: () => void;
  profitRate?: number;
};

const ModifyOrderTakeProfitContainer: FC<Props> = ({
  accountId,
  orderId,
  onClose,
  priceDecimalScale,
  takeProfit,
  ask,
  bid,
  orderType,
  volume,
  stopLoss,
  baseCurrency,
  contractSize,
  currency,
  openPrice,
  quoteCurrency,
  currencyDecimalScale,
  profitRate,
}) => {
  const { symbols } = useSymbolsContext();

  const { mutateAsync: modifyOrder } = useModifyTerminalOrderMutation();

  const handleSubmit: ComponentProps<typeof ModifyTakeProfitForm>["onSubmit"] = useCallback(
    ({ takeProfit }) => {
      return modifyOrder(
        {
          tradingAccountId: accountId,
          id: orderId,
          terminalModifyOrderRequest: {
            stopLoss,
            price: openPrice,
            takeProfit: getInputNumberValue(takeProfit),
          },
        },
        {
          onSuccess: () => {
            onClose();
          },
        },
      );
    },
    [modifyOrder, accountId, orderId, onClose, stopLoss, openPrice],
  );

  return (
    <ModifyTakeProfitForm
      onSubmit={handleSubmit}
      priceDecimalScale={priceDecimalScale}
      takeProfit={takeProfit}
      ask={ask}
      bid={bid}
      orderType={orderType}
      openPrice={openPrice}
      baseCurrency={baseCurrency}
      contractSize={contractSize}
      currency={currency}
      quoteCurrency={quoteCurrency}
      volume={volume}
      currencyDecimalScale={currencyDecimalScale}
      symbols={symbols}
      profitRate={profitRate}
    />
  );
};

export { ModifyOrderTakeProfitContainer };
