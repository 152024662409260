import { type ElementRef, forwardRef, type ReactNode } from "react";

import { IconClose } from "@/domains/icons";
import { cn } from "@/shared/styles";
import { Dialog, IconButton, overlayStyles, Text } from "@/shared/ui";

type Props = {
  title: string;
  children: ReactNode;
};

const TerminalDialogContent = forwardRef<ElementRef<typeof Dialog.PrimitiveContent>, Props>(
  ({ children, title }, forwardedRef) => (
    <Dialog.Portal>
      <Dialog.Overlay
        className={cn(
          overlayStyles({ hasAnimation: false }),
          "grid place-items-center overflow-y-auto overflow-x-hidden scrollbar-hide",
        )}
      >
        <Dialog.PrimitiveContent
          className="relative z-50 flex h-full w-full flex-col overflow-y-auto overflow-x-hidden bg-surface-canvas p-4 pt-0 outline-none"
          ref={forwardedRef}
        >
          <div className="flex items-center justify-between gap-2 py-4">
            <Text as="h2" variant="XL / Medium" color="primary">
              {title}
            </Text>
            <div className="flex items-center gap-4">
              <Dialog.Close asChild>
                <IconButton size="sm" variant="flat">
                  <IconClose />
                </IconButton>
              </Dialog.Close>
            </div>
          </div>
          <div className="-mx-4 flex h-full flex-col overflow-y-auto px-4 pt-2 scrollbar-hide">{children}</div>
        </Dialog.PrimitiveContent>
      </Dialog.Overlay>
    </Dialog.Portal>
  ),
);

export { TerminalDialogContent };
