import { useTerminalAccountContext } from "../contexts/account.context";
import { useSymbolsContext } from "../contexts/symbols.context";
import { useLayoutContext } from "../layout/context";
import { useCurrentSymbolContext } from "../symbol-info/current-symbol-context";
import { ChartSymbols } from "./chart-symbols";

const ChartSymbolsContainer = () => {
  const { account } = useTerminalAccountContext();
  const { setSymbol, symbolInfo } = useCurrentSymbolContext();

  const { favorites, symbolsList } = useSymbolsContext();

  const { watchlistOpen, closeWatchlist, isMobile, watchlistOnChange, openWatchlist } = useLayoutContext();

  return (
    <ChartSymbols
      openWatchlist={openWatchlist}
      symbolInfo={symbolInfo}
      closeWatchlist={closeWatchlist}
      isMobile={isMobile}
      watchlistOnChange={watchlistOnChange}
      watchlistOpen={watchlistOpen}
      accountId={account.id!}
      favoriteSymbols={favorites.charts!}
      setSymbol={setSymbol}
      symbolsList={symbolsList}
    />
  );
};

export { ChartSymbolsContainer };
