import * as ProgressPrimitive from "@radix-ui/react-progress";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from "react";

import { cn } from "@/shared/styles";

type Props = ComponentPropsWithoutRef<typeof ProgressPrimitive.Root> & {
  variant: "positive" | "warning" | "negative";
};

const Progress = forwardRef<ElementRef<typeof ProgressPrimitive.Root>, Props>(
  ({ className, value, variant, ...props }, ref) => (
    <ProgressPrimitive.Root
      ref={ref}
      className={cn(
        "relative h-2 w-full overflow-hidden rounded-full",
        {
          "bg-positive-bg/25": variant === "positive",
          "bg-warning-bg/25": variant === "warning",
          "bg-negative-bg/25": variant === "negative",
        },
        className,
      )}
      style={{
        // Fix overflow clipping in Safari
        // https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0
        transform: "translateZ(0)",
      }}
      {...props}
    >
      <ProgressPrimitive.Indicator
        className={cn("size-full transition", {
          "bg-positive-bg": variant === "positive",
          "bg-warning-bg": variant === "warning",
          "bg-negative-bg": variant === "negative",
        })}
        style={{ transform: `translateX(-${Math.max(0, 100 - (value || 0))}%)` }}
      />
    </ProgressPrimitive.Root>
  ),
);
Progress.displayName = ProgressPrimitive.Root.displayName;

export { Progress };
