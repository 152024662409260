import type { FC } from "react";
import { memo } from "react";
import { useTranslation } from "react-i18next";

import { NumberFormat } from "@/app/components";
import { useAddChartSymbol } from "@/features/terminal/hooks/add-chart-symbol.hook";
import { terminalLocators } from "@/features/terminal/locators";
import type { PendingOrder } from "@/features/terminal/orders/extended-orders.context";
import { terminalFormatDate } from "@/features/terminal/place-order/place-order.helpers";
import { useCurrentSymbolContext } from "@/features/terminal/symbol-info/current-symbol-context";
import { NewButton } from "@/shared/ui";

import { OpenPricePopoverContent } from "../../modify-order/open-price/popover-content";
import { StopLossPopoverContent } from "../../modify-order/stop-loss/popover-content";
import { TakeProfitPopoverContent } from "../../modify-order/take-profit/popover-content";
import { CancelPendingOrderButton } from "../../pending-orders/cancel/cancel-button";
import { ModifyOrderButton } from "../../ui/modify-order/button";
import { OrderTypeFormat } from "../../ui/order-type-format";
import { TradingTable } from "../table";

type Props = {
  order: PendingOrder;
  accountId: string;
  currency: string;
  currencyDecimalScale: number;
  setSymbol: ReturnType<typeof useCurrentSymbolContext>["setSymbol"];
};

const PendingOrdersBodyRow: FC<Props> = ({ order, accountId, setSymbol, currency, currencyDecimalScale }) => {
  const { t } = useTranslation();

  const {
    symbol,
    type,
    volume,
    price,
    stopLoss,
    takeProfit,
    id,
    date,
    currentPrice,
    ask,
    bid,
    priceDecimalScale,
    volumeDecimalScale,
    baseCurrency,
    quoteCurrency,
    contractSize,
    margin,
  } = order;

  const { addChartSymbol } = useAddChartSymbol({ setSymbol, accountId });

  return (
    <TradingTable.Row>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.pending.symbol}>
        <TradingTable.Symbol symbol={symbol} onClick={() => addChartSymbol(symbol)} />
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.pending.type}>
        <OrderTypeFormat type={type} />
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.pending.volume}>
        <NumberFormat value={volume} decimalScale={volumeDecimalScale} />
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.pending.margin}>
        <NumberFormat value={margin} decimalScale={currencyDecimalScale} />
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.pending.openPrice}>
        <ModifyOrderButton value={<NumberFormat value={price} decimalScale={priceDecimalScale} />}>
          {onClose => (
            <OpenPricePopoverContent
              onClose={onClose}
              openPrice={price}
              orderType={type}
              orderId={id}
              priceDecimalScale={priceDecimalScale}
              stopLoss={stopLoss}
              takeProfit={takeProfit}
              ask={ask}
              bid={bid}
              accountId={accountId}
            />
          )}
        </ModifyOrderButton>
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.pending.currentPrice}>
        {currentPrice ? <NumberFormat value={currentPrice} decimalScale={priceDecimalScale} /> : "—"}
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.pending.takeProfit}>
        <ModifyOrderButton
          value={takeProfit ? <NumberFormat value={takeProfit} decimalScale={priceDecimalScale} /> : null}
        >
          {onClose => (
            <TakeProfitPopoverContent
              onClose={onClose}
              openPrice={price}
              orderType={type}
              orderId={id}
              priceDecimalScale={priceDecimalScale}
              stopLoss={stopLoss}
              takeProfit={takeProfit}
              ask={ask}
              bid={bid}
              accountId={accountId}
              baseCurrency={baseCurrency}
              quoteCurrency={quoteCurrency}
              contractSize={contractSize}
              volume={volume}
              currency={currency}
              currencyDecimalScale={currencyDecimalScale}
            />
          )}
        </ModifyOrderButton>
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.pending.stopLoss}>
        <ModifyOrderButton value={stopLoss ? <NumberFormat value={stopLoss} decimalScale={priceDecimalScale} /> : null}>
          {onClose => (
            <StopLossPopoverContent
              onClose={onClose}
              openPrice={price}
              orderType={type}
              orderId={id}
              priceDecimalScale={priceDecimalScale}
              stopLoss={stopLoss}
              takeProfit={takeProfit}
              ask={ask}
              bid={bid}
              accountId={accountId}
              baseCurrency={baseCurrency}
              quoteCurrency={quoteCurrency}
              contractSize={contractSize}
              volume={volume}
              currency={currency}
              currencyDecimalScale={currencyDecimalScale}
            />
          )}
        </ModifyOrderButton>
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.pending.placementTime}>
        {terminalFormatDate(date)}
      </TradingTable.Cell>
      <TradingTable.Cell align="end" data-test={terminalLocators.tradingTables.pending.delete}>
        <CancelPendingOrderButton orderId={id} accountId={accountId}>
          {({ cancelOrder, isLoading }) => (
            <NewButton onClick={cancelOrder} pending={isLoading} variant="flat" layout="auto" size="sm">
              {t("terminal.orders.cancel-pending-order")}
            </NewButton>
          )}
        </CancelPendingOrderButton>
      </TradingTable.Cell>
    </TradingTable.Row>
  );
};

const Component = memo(PendingOrdersBodyRow);

export { Component as PendingOrdersBodyRow };
