import { useEffect, useState } from "react";

import { calculateSymbolPriceChange } from "../helpers/formulas";

const usePriceChange = ({
  bid,
  priceLast24H,
}: {
  bid: number | undefined | null;
  priceLast24H: number | null | undefined;
}) => {
  const [priceChange, setPriceChange] = useState(() => calculateSymbolPriceChange({ bid, priceLast24H }));

  useEffect(() => {
    setPriceChange(calculateSymbolPriceChange({ bid, priceLast24H }));
  }, [bid, priceLast24H]);

  return priceChange;
};

export { usePriceChange };
