import { type TradingAccount, TradingAccountType } from "@/services/openapi";

const getRealDemoAccounts = (accounts: TradingAccount[]) => {
  const demoAccounts: TradingAccount[] = [];
  const realAccounts: TradingAccount[] = [];

  accounts.forEach(account => {
    if (account.type === TradingAccountType.Demo) {
      demoAccounts.push(account);
    } else {
      realAccounts.push(account);
    }
  });

  return { demoAccounts, realAccounts };
};

export { getRealDemoAccounts };
