import { Slot, Slottable } from "@radix-ui/react-slot";
import { type VariantProps } from "class-variance-authority";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef, memo, useMemo } from "react";

import { cn } from "@/shared/styles";

import { buttonStyles } from "../button";
import { Spinner } from "../spinner";

type Props = ComponentPropsWithoutRef<"button"> &
  Omit<VariantProps<typeof buttonStyles>, "layout" | "fullWidth"> & {
    asChild?: boolean;
    pending?: boolean;
  };

const IconButton = forwardRef<ElementRef<"button">, Props>(
  (
    {
      className,
      variant = "primary",
      color = "accent",
      size = "md",
      asChild = false,
      pending,
      disabled: disabledProp,
      type = "button",
      children,
      checked,
      ...props
    },
    ref,
  ) => {
    const disabled = disabledProp || pending;
    const Comp = asChild && !pending ? Slot : "button";

    return (
      <Comp
        className={useMemo(
          () =>
            cn(
              buttonStyles({ variant, size, pending, color, checked }),
              "shrink-0 items-center justify-center p-0",
              {
                "w-14 *:size-6": size === "md",
                "w-10 *:size-6": size === "sm",
              },
              className,
            ),
          [variant, size, className, pending, color, checked],
        )}
        disabled={disabled}
        type={type}
        ref={ref}
        {...props}
      >
        {!pending && <Slottable>{children}</Slottable>}
        {pending && <Spinner />}
      </Comp>
    );
  },
);

const Component = memo(IconButton);

Component.displayName = "IconButton";

export { IconButton };
