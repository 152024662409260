import dayjs from "dayjs";
import { type FC, memo, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { type TerminalSession } from "@/services/openapi";
import { DataList } from "@/shared/ui";

import { SymbolInfoBlock } from "../ui/block";
import { getSessionsInfo } from "./helpers";

type Props = {
  sessions: TerminalSession[];
};

const SymbolInfoScheduleBlock: FC<Props> = ({ sessions }) => {
  const { t } = useTranslation();

  const sessionsInfo = useMemo(
    () => getSessionsInfo({ sessions, utcOffsetInSeconds: dayjs().utcOffset() * 60 }),
    [sessions],
  );

  return (
    <SymbolInfoBlock>
      <DataList>
        {sessionsInfo.map(({ day, value }) => (
          <DataList.Item
            key={day}
            label={<DataList.Label className="first-letter:capitalize">{day}</DataList.Label>}
            value={<DataList.Value>{value || t("terminal.symbol-info.market-closed")}</DataList.Value>}
          />
        ))}
      </DataList>
    </SymbolInfoBlock>
  );
};

const Component = memo(SymbolInfoScheduleBlock);

export { Component as SymbolInfoScheduleBlock };
