import type { TFunction } from "i18next";

import { TerminalDealType } from "@/services/openapi";

import type { MergedTerminalSymbol } from "../helpers/symbols";

const getTypeColor = ({ type }: { type: TerminalDealType }) => {
  switch (type) {
    case TerminalDealType.Buy:
    case TerminalDealType.BuyLimit:
    case TerminalDealType.BuyStop:
    case TerminalDealType.BuyStopLimit:
      return "positive";
    default:
      return "negative";
  }
};

const getOrderTypeText = ({ t, type }: { t: TFunction; type: TerminalDealType }) => {
  switch (type) {
    case TerminalDealType.Buy:
      return t("terminal.types.buy");
    case TerminalDealType.BuyLimit:
      return t("terminal.types.buy-limit");
    case TerminalDealType.BuyStop:
      return t("terminal.types.buy-stop");
    case TerminalDealType.BuyStopLimit:
      return t("terminal.types.buy-stop-limit");
    case TerminalDealType.Sell:
      return t("terminal.types.sell");
    case TerminalDealType.SellLimit:
      return t("terminal.types.sell-limit");
    case TerminalDealType.SellStop:
      return t("terminal.types.sell-stop");
    case TerminalDealType.SellStopLimit:
      return t("terminal.types.sell-stop-limit");
  }
};

const getOrderCurrentPrice = ({
  type,
  ask,
  bid,
  inverse,
}: {
  type: TerminalDealType;
  ask: number | undefined;
  bid: number | undefined;
  inverse?: boolean;
}) => {
  switch (type) {
    case TerminalDealType.Sell:
    case TerminalDealType.BuyLimit:
    case TerminalDealType.BuyStop:
      return inverse ? bid : ask;
    case TerminalDealType.Buy:
    case TerminalDealType.SellLimit:
    case TerminalDealType.SellStop:
      return inverse ? ask : bid;
  }
};

const getBidAsk = ({
  ask,
  bid,
  symbol,
  symbolInfo,
}: {
  ask: number | undefined | null;
  bid: number | undefined | null;
  symbol: string;
  symbolInfo: MergedTerminalSymbol;
}) => {
  if (symbolInfo.symbol === symbol) {
    return { ask: symbolInfo.priceAsk || 0, bid: symbolInfo.priceBid || 0 };
  }

  return { ask: ask || 0, bid: bid || 0 };
};

export { getOrderTypeText, getTypeColor, getOrderCurrentPrice, getBidAsk };
