import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { useLayoutContext } from "@/features/terminal/layout/context";
import { terminalLocators } from "@/features/terminal/locators";
import { useOrdersContext } from "@/features/terminal/orders/context";
import { useExtendedOrdersContext } from "@/features/terminal/orders/extended-orders.context";
import { NewButton } from "@/shared/ui";

import { CloseAllOpenOrdersButton } from "../../open-orders/close-all/button";
import { TradingTableEmptyState } from "../../ui/empty-state";
import { TradingCardsWrapper } from "../cards-wrapper";
import { OpenOrderCard } from "./card";

type Props = {
  accountId: string;
  currency: string;
  currencyDecimalScale: number;
  orders: ReturnType<typeof useExtendedOrdersContext>["extendedOpenOrders"];
  ordersIsLoading: ReturnType<typeof useOrdersContext>["ordersIsLoading"];
  openWatchlist: ReturnType<typeof useLayoutContext>["openWatchlist"];
  onClose?: () => void;
};

const OpenOrdersCardsContainer: FC<Props> = ({
  orders,
  accountId,
  currency,
  onClose,
  openWatchlist,
  ordersIsLoading,
  currencyDecimalScale,
}) => {
  const { t } = useTranslation();

  return (
    <TradingCardsWrapper
      items={orders}
      emptyState={
        <TradingTableEmptyState
          title={t("terminal.empty-state.open-title")}
          description={t("terminal.empty-state.open-description")}
          action={
            <TradingTableEmptyState.Button
              onClick={() => {
                onClose && onClose();
                openWatchlist();
              }}
            >
              {t("terminal.go-to-watchlist")}
            </TradingTableEmptyState.Button>
          }
        />
      }
      isLoading={ordersIsLoading}
      action={
        orders.length > 1 ? (
          <CloseAllOpenOrdersButton
            accountId={accountId}
            currency={currency}
            currencyDecimalScale={currencyDecimalScale}
          >
            {opened => (
              <NewButton
                layout="auto"
                checked={opened}
                variant="secondary"
                data-test={terminalLocators.tradingTables.open.closeAll}
                fullWidth
              >
                {t("terminal.orders.close-all.name")}
              </NewButton>
            )}
          </CloseAllOpenOrdersButton>
        ) : null
      }
    >
      {order => (
        <OpenOrderCard
          key={order.id}
          order={order}
          accountId={accountId}
          currency={currency}
          currencyDecimalScale={currencyDecimalScale}
        />
      )}
    </TradingCardsWrapper>
  );
};

export { OpenOrdersCardsContainer };
