import { type ComponentProps, type FC } from "react";

import { Popover } from "@/shared/ui";

type Props = ComponentProps<typeof Popover.Content>;

const TradingTablesPopoverContent: FC<Props> = ({ title, tooltip, ...props }) => {
  return <Popover.Content title={title} titleSize="sm" tooltip={tooltip} hideClose {...props} />;
};

export { TradingTablesPopoverContent };
