import { useStore } from "@nanostores/react";
import { Trans } from "react-i18next";

import { IconTimeRound } from "@/domains/icons";
import { useCurrentSymbolContext } from "@/features/terminal/symbol-info/current-symbol-context";
import { InfoBlock } from "@/shared/ui";

import { $serverTime } from "../../helpers/server-time";
import { useTradingUnavailableInfo } from "../../helpers/symbols";

const TradingUnavailableAlert = () => {
  const { symbolInfo, currentSymbol } = useCurrentSymbolContext();

  const currentDate = useStore($serverTime);

  const { date, showStatus, time, timeLeftMinutes, timezone } = useTradingUnavailableInfo({
    periodDateEnd: symbolInfo.periodDateEnd!,
    currentDate: currentDate!,
  });

  return (
    <InfoBlock
      variant="warning"
      icon={<IconTimeRound />}
      text={
        <>
          {showStatus === "date" && (
            <Trans
              i18nKey="terminal.trading-unavailable.reopen-message.date"
              values={{ currentSymbol, date, time, timezone }}
              components={{
                strong: <InfoBlock.Strong />,
              }}
            />
          )}
          {showStatus === "today" && (
            <Trans
              i18nKey="terminal.trading-unavailable.reopen-message.today-at"
              values={{ currentSymbol, time, timezone }}
              components={{
                strong: <InfoBlock.Strong />,
              }}
            />
          )}
          {showStatus === "time" && (
            <Trans
              i18nKey="terminal.trading-unavailable.reopen-message.time"
              values={{ currentSymbol, time: timeLeftMinutes }}
              components={{
                strong: <InfoBlock.Strong />,
              }}
            />
          )}
        </>
      }
    />
  );
};

export { TradingUnavailableAlert };
