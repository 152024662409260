import type { ComponentPropsWithoutRef, ElementRef, FC, ReactNode } from "react";
import { forwardRef } from "react";

import { cn } from "@/shared/styles";
import { Text } from "@/shared/ui";

const DataListRoot = forwardRef<ElementRef<"dl">, ComponentPropsWithoutRef<"dl">>(({ className, ...props }, ref) => {
  return <dl className={cn("flex flex-col gap-3 py-1.5", className)} {...props} ref={ref} />;
});

const Item: FC<{ label?: ReactNode; value?: ReactNode }> = ({ label, value }) => {
  return (
    <div className="flex items-center justify-between gap-1">
      {label}
      <div role="separator" className="flex-1 border-t border-dashed border-contrast-quaternary" />
      {value}
    </div>
  );
};

const Label: FC<ComponentPropsWithoutRef<typeof Text>> = props => {
  return <Text as="dt" variant="S / Regular" color="secondary" {...props} />;
};

const Value: FC<ComponentPropsWithoutRef<typeof Text>> = props => {
  return <Text as="dd" variant="S / Regular" color="primary" {...props} />;
};

const Component = Object.assign(DataListRoot, { Item, Label, Value });

export { Component as DataList };
