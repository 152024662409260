import { type FC, type ReactElement, type ReactNode } from "react";

import { TerminalCloseOrderType } from "@/services/openapi";
import { useCloseTerminalOrdersMutation } from "@/state/server/terminal";

type Props = {
  accountId: string;
  orderId: number;
  children: (params: { cancelOrder: () => void; isLoading: boolean }) => ReactNode;
};

const CancelPendingOrderButton: FC<Props> = ({ accountId, orderId, children }) => {
  const { mutate, isLoading } = useCloseTerminalOrdersMutation();

  const cancelOrder = () => {
    mutate({
      tradingAccountId: accountId,
      terminalCloseOrderRequest: {
        id: orderId,
        type: TerminalCloseOrderType.Limit,
      },
    });
  };

  return children({ isLoading, cancelOrder }) as ReactElement;
};

export { CancelPendingOrderButton };
