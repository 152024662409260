import { cva, type VariantProps } from "class-variance-authority";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef, type ReactElement, type ReactNode } from "react";

import { TooltipQuestionIcon } from "@/components/icons";
import { cn } from "@/shared/styles";

import { Tooltip } from "../../tooltip";
import { _Descriptor } from "./_descriptor";

const inputStyles = cva(
  "has-[input:focus]:border-accent-text has-[input:focus]:bg-input-bg-active has-[label:hover]:bg-input-bg-hover has-[input:focus]:shadow-focus",
  {
    variants: {
      invalid: {
        true: "has-[input:focus]:border-negative-text has-[label:hover]:border-negative-text has-[input:focus]:shadow-error",
      },
    },
  },
);

const selectStyles = cva(
  "outline-none hover:bg-input-bg-hover focus:border-accent-text focus:shadow-focus data-[state=open]:border-accent-text",
  {
    variants: {
      invalid: {
        true: "hover:border-negative-bg focus:border-negative-bg focus:shadow-error data-[state=open]:border-negative-bg",
      },
    },
  },
);

const labelStyles = cva("flex h-full grow flex-col justify-center gap-0.5", {
  variants: {
    bottomLabel: {
      true: "flex-col-reverse",
    },
  },
});

const wrapperStyles = cva(
  "inline-flex w-full items-center justify-between gap-3 rounded-[16px] border border-input-border bg-input-bg px-4 text-start transition",
  {
    variants: {
      size: {
        sm: "h-[52px]",
        md: "h-14",
        lg: "h-16",
      },
      invalid: {
        true: "border-negative-bg",
      },
      disabled: {
        true: "pointer-events-none",
      },
      pending: {
        true: "animate-pulse",
      },
    },
  },
);

type _ExternalWrapperProps = VariantProps<typeof wrapperStyles> &
  VariantProps<typeof labelStyles> & {
    className?: string;
    label?: ReactNode;
    startSection?: ReactNode;
    endSection?: ReactNode;
    tooltipContent?: ReactNode;
    descriptor?: ReactNode;
    renderWrapper?: (element: ReactElement) => ReactNode; // needed for place order suggester and feature-tour
  };

type Props = ComponentPropsWithoutRef<"div"> &
  _ExternalWrapperProps & {
    type: "input" | "select";
  };

const _Wrapper = forwardRef<ElementRef<"div">, Props>(
  (
    {
      size = "md",
      children,
      className,
      startSection,
      endSection,
      disabled,
      pending,
      label,
      invalid,
      descriptor,
      renderWrapper,
      tooltipContent,
      type,
      bottomLabel,
      ...props
    },
    ref,
  ) => {
    const WrapperTag: keyof JSX.IntrinsicElements = type === "select" ? "button" : "div";
    const LabelTag: keyof JSX.IntrinsicElements = type === "select" ? "div" : "label";

    const element = (
      <WrapperTag
        className={cn(
          wrapperStyles({ size, disabled, pending, invalid }),
          type === "select" && selectStyles({ invalid }),
          type === "input" && inputStyles({ invalid }),
        )}
        {...props}
        // @ts-ignore
        ref={ref}
      >
        {startSection}
        <LabelTag className={labelStyles({ bottomLabel })}>
          {label && <div className="font-roboto text-[12px] leading-[1.5] text-contrast-secondary">{label}</div>}
          {children}
        </LabelTag>
        {(endSection || tooltipContent) && (
          <div className="flex items-center gap-3">
            {tooltipContent && (
              <Tooltip content={tooltipContent}>
                <div className="relative grid size-6 place-items-center before:absolute before:size-6">
                  <TooltipQuestionIcon size="sm" />
                </div>
              </Tooltip>
            )}
            {endSection}
          </div>
        )}
      </WrapperTag>
    );

    return (
      <div
        className={cn("group/input-wrapper", className)}
        data-size={size}
        data-invalid={invalid}
        data-disabled={disabled}
      >
        {renderWrapper ? renderWrapper(element) : element}
        {descriptor && <_Descriptor>{descriptor}</_Descriptor>}
      </div>
    );
  },
);

export { _Wrapper };
export type { _ExternalWrapperProps };
