import { cva } from "class-variance-authority";

import { useTranslation } from "@/hooks/translator.hook";
import { Text, UnstyledButton } from "@/shared/ui";

import { TerminalTableState, useLayoutContext } from "../../layout/context";
import { useOrdersContext } from "../../orders/context";

const itemStyles = cva(
  "relative grid h-10 grow place-items-center outline-none after:absolute after:end-0 after:h-5 after:w-px after:bg-contrast-quaternary last:after:hidden",
)();

const circleStyles = cva(
  "relative before:absolute before:-end-3 before:-top-0.5 before:size-2 before:rounded-full before:bg-negative-text",
)();

const MobileTradingTablesBlock = () => {
  const { t } = useTranslation();
  const { changeTable } = useLayoutContext();
  const { openOrders, pendingOrders } = useOrdersContext();

  return (
    <div className="flex border-t border-contrast-quaternary bg-surface-canvas">
      <UnstyledButton className={itemStyles} onClick={() => changeTable(TerminalTableState.OPEN)}>
        <Text variant="S / Medium" color="primary" className={openOrders.length > 0 ? circleStyles : void 0}>
          {t("terminal.open")}: {openOrders.length}
        </Text>
      </UnstyledButton>
      <UnstyledButton className={itemStyles} onClick={() => changeTable(TerminalTableState.PENDING)}>
        <Text variant="S / Medium" color="primary" className={pendingOrders.length > 0 ? circleStyles : void 0}>
          {t("terminal.pending")}: {pendingOrders.length}
        </Text>
      </UnstyledButton>
      <UnstyledButton className={itemStyles} onClick={() => changeTable(TerminalTableState.CLOSED_POSITIONS)}>
        <Text variant="S / Medium" color="primary">
          {t("terminal.closed-positions")}
        </Text>
      </UnstyledButton>
    </div>
  );
};

export { MobileTradingTablesBlock };
