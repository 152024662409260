import Big from "big.js";
import { cva } from "class-variance-authority";
import { type FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { PnlFormat } from "@/app/components";
import { getNumberTextColor } from "@/app/ui/colors";
import { useTerminalAccountSummary } from "@/features/terminal/account-summary/context";
import { useExtendedOrdersContext } from "@/features/terminal/orders/extended-orders.context";
import { TerminalCloseOrderType } from "@/services/openapi";
import { NewButton, Popover, RadioGroup, Text } from "@/shared/ui";
import { useCloseTerminalOrdersMutation } from "@/state/server/terminal";

const radioItemWrapperStyles = cva("rounded-[16px] border border-card-border bg-card-bg-accent px-4 py-3")();

enum CloseType {
  All = "all",
  Profitable = "profitable",
}

type Props = {
  accountId: string;
  currency: string;
  currencyDecimalScale: number;
  onClose: () => void;
};

const CloseAllOpenOrdersPopoverContent: FC<Props> = ({ onClose, currencyDecimalScale, currency, accountId }) => {
  const { t } = useTranslation();

  const { pnl } = useTerminalAccountSummary();

  const { extendedOpenOrders } = useExtendedOrdersContext();

  const { profitablePnl, profitableCount } = useMemo(() => {
    let profitablePnl = new Big(0);
    let profitableCount = 0;

    extendedOpenOrders.forEach(({ pnl }) => {
      if (pnl > 0) {
        profitablePnl = profitablePnl.plus(pnl);
        profitableCount++;
      }
    });

    return { profitablePnl: profitablePnl.toNumber(), profitableCount };
  }, [extendedOpenOrders]);

  const profitableDisabled = profitableCount === 0;

  const { mutate, isLoading } = useCloseTerminalOrdersMutation();

  const [closeType, setCloseType] = useState<string>(CloseType.All);

  const closeOrders = () => {
    mutate(
      {
        tradingAccountId: accountId,
        terminalCloseOrderRequest: {
          type: TerminalCloseOrderType.Market,
          onlyProfitable: closeType === "profitable",
        },
      },
      {
        onSuccess: () => {
          onClose();
        },
      },
    );
  };

  return (
    <>
      <RadioGroup className="gap-2" value={closeType} onValueChange={setCloseType}>
        <div className={radioItemWrapperStyles}>
          <RadioGroup.Item
            label={t("terminal.orders.close-all.all-trades-count", { count: extendedOpenOrders.length })}
            value={CloseType.All}
            description={
              <Text variant="inherit" color={getNumberTextColor(pnl) || "inherit"}>
                <PnlFormat value={pnl} currency={currency} decimalScale={currencyDecimalScale} />
              </Text>
            }
          />
        </div>
        <div className={radioItemWrapperStyles}>
          <RadioGroup.Item
            disabled={profitableDisabled}
            label={t("terminal.orders.close-all.profitable-trades-count", { count: profitableCount })}
            value={CloseType.Profitable}
            description={
              <Text variant="inherit" color={getNumberTextColor(profitablePnl) || "inherit"}>
                <PnlFormat value={profitablePnl} currency={currency} decimalScale={currencyDecimalScale} />
              </Text>
            }
          />
        </div>
      </RadioGroup>
      <Popover.Footer>
        <NewButton
          pending={isLoading}
          layout="auto"
          size="sm"
          onClick={() => {
            if (profitableDisabled && closeType === CloseType.Profitable) {
              return;
            }
            closeOrders();
          }}
        >
          {t("terminal.orders.close-all.action")}
        </NewButton>
        <Popover.Close asChild>
          <NewButton layout="auto" variant="secondary" size="sm">
            {t("terminal.orders.close-all.cancel")}
          </NewButton>
        </Popover.Close>
      </Popover.Footer>
    </>
  );
};

export { CloseAllOpenOrdersPopoverContent };
