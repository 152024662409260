import type { FC, ReactNode } from "react";

import { useDisclosure } from "@/hooks/disclosure.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { Popover } from "@/shared/ui";

import { CloseAllOpenOrdersPopoverContent } from "./popover-content";

type Props = {
  accountId: string;
  currency: string;
  currencyDecimalScale: number;
  children: (opened: boolean) => ReactNode;
};

const CloseAllOpenOrdersButton: FC<Props> = ({ accountId, currency, currencyDecimalScale, children }) => {
  const { t } = useTranslation();

  const [opened, { onOpenChange, close }] = useDisclosure();

  return (
    <Popover open={opened} onOpenChange={onOpenChange}>
      <Popover.Trigger asChild>{children(opened)}</Popover.Trigger>
      <Popover.Content title={t("terminal.orders.close-all.title")!} titleSize="sm" hideClose>
        <CloseAllOpenOrdersPopoverContent
          accountId={accountId}
          currency={currency}
          currencyDecimalScale={currencyDecimalScale}
          onClose={close}
        />
      </Popover.Content>
    </Popover>
  );
};

export { CloseAllOpenOrdersButton };
