import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { useLayoutContext } from "@/features/terminal/layout/context";
import { useOrdersContext } from "@/features/terminal/orders/context";
import { useExtendedOrdersContext } from "@/features/terminal/orders/extended-orders.context";

import { TradingTableEmptyState } from "../../ui/empty-state";
import { TradingCardsWrapper } from "../cards-wrapper";
import { PendingOrderCard } from "./card";

type Props = {
  accountId: string;
  currency: string;
  currencyDecimalScale: number;
  orders: ReturnType<typeof useExtendedOrdersContext>["extendedPendingOrders"];
  ordersIsLoading: ReturnType<typeof useOrdersContext>["ordersIsLoading"];
  openWatchlist: ReturnType<typeof useLayoutContext>["openWatchlist"];
  onClose?: () => void;
};

const PendingOrdersCardsContainer: FC<Props> = ({
  orders,
  accountId,
  onClose,
  currency,
  openWatchlist,
  ordersIsLoading,
  currencyDecimalScale,
}) => {
  const { t } = useTranslation();

  return (
    <TradingCardsWrapper
      items={orders}
      isLoading={ordersIsLoading}
      emptyState={
        <TradingTableEmptyState
          title={t("terminal.empty-state.pending-title")}
          description={t("terminal.empty-state.pending-description")}
          action={
            <TradingTableEmptyState.Button
              onClick={() => {
                onClose && onClose();
                openWatchlist();
              }}
            >
              {t("terminal.go-to-watchlist")}
            </TradingTableEmptyState.Button>
          }
        />
      }
    >
      {order => (
        <PendingOrderCard
          key={order.id}
          order={order}
          accountId={accountId}
          currency={currency}
          currencyDecimalScale={currencyDecimalScale}
        />
      )}
    </TradingCardsWrapper>
  );
};

export { PendingOrdersCardsContainer };
