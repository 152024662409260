import { type FC } from "react";

import { amplitudeEvents, track } from "@/app/libs/amplitude";
import { IconInfo } from "@/domains/icons";
import { Dialog, IconButton } from "@/shared/ui";

import { SymbolInfoDialogContent } from "./content";

const SymbolInfoDialogButton: FC = () => {
  return (
    <Dialog>
      <Dialog.Trigger asChild>
        <IconButton size="sm" variant="flat" onClick={() => track(amplitudeEvents.terminal.openInstrumentDetails)}>
          <IconInfo />
        </IconButton>
      </Dialog.Trigger>
      <Dialog.Content size="lg">
        <SymbolInfoDialogContent />
      </Dialog.Content>
    </Dialog>
  );
};

export { SymbolInfoDialogButton };
