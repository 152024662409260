import { type FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { usePlaceOrderContext } from "../context";

type Props = {
  sliderValue: ReturnType<typeof usePlaceOrderContext>["sliderValue"];
};

const _Tooltip: FC<Props> = ({ sliderValue }) => {
  const { t } = useTranslation();

  const label = useMemo(() => {
    const value = sliderValue[0];

    if (value === 100) {
      return t("common.max");
    }
    if (value === 0) {
      return t("common.min");
    }
    return `${value}%`;
  }, [sliderValue, t]);

  return (
    <div className="pointer-events-none absolute start-1/2 z-10 hidden -translate-x-1/2 -translate-y-full flex-col items-center group-has-[:focus]:flex">
      <div className="rounded-[8px] bg-accent-bg px-4 py-2 shadow-modal">
        <div className="typography-S-Regular text-static-white">{label}</div>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="8" viewBox="0 0 17 8" fill="none">
        <path d="M8.5 8L0.5 0L16.5 0L8.5 8Z" fill="currentColor" />
      </svg>
    </div>
  );
};

export { _Tooltip };
