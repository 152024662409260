import Big from "big.js";

import { BannerState, type BonusUserPlatform } from "@/services/openapi";

const getShowBonusCondition = ({
  allowedTradingServerAccounts,
  serverAccountId,
}: {
  allowedTradingServerAccounts: string[];
  serverAccountId: string;
}) => allowedTradingServerAccounts.includes(serverAccountId);

const getBonusMainPercent = ({ amountSet }: BonusUserPlatform) => amountSet![0]!.amount;

const getShowBonusBanner = ({ options }: BonusUserPlatform, isDepositPage?: boolean) => {
  if (isDepositPage) {
    return options!.bannerDepositState === BannerState.Default;
  }

  return options!.bannerAccountsState === BannerState.Default;
};

type BonusRisk = {
  value: number;
  type: "safe" | "warning" | "danger";
};

const getBonusRisk = ({ equity, credit }: { equity: number; credit: number }): BonusRisk => {
  if (!equity || !credit) {
    return {
      value: 0,
      type: "safe",
    };
  }

  const risk = new Big(credit).div(equity).mul(100).round(0).toNumber();

  if (risk >= 75) {
    return {
      value: risk,
      type: "danger",
    };
  }

  if (risk >= 60) {
    return {
      value: risk,
      type: "warning",
    };
  }

  return {
    value: risk,
    type: "safe",
  };
};

export { getShowBonusCondition, getBonusMainPercent, getShowBonusBanner, getBonusRisk };
export type { BonusRisk };
