import type { FC } from "react";

import { IconClose } from "@/domains/icons";
import { IconButton, Text } from "@/shared/ui";

import { useSignalContext } from "../../signal/context";
import { SignalTextFormat } from "../../signal/text-format";
import { useCurrentSymbolContext } from "../../symbol-info/current-symbol-context";
import { PlaceOrderContainer } from "../container";
import { TerminalPlaceOrderProvider } from "../context";

const PlaceOrderSignalBlock: FC = () => {
  const { closeSignalForm } = useSignalContext();
  const { symbolInfo } = useCurrentSymbolContext();

  return (
    <div className="flex min-h-0 grow flex-col bg-surface-canvas pt-4 animate-in fade-in-0">
      <div className="flex items-center justify-between gap-2 px-5">
        <Text as="h5" color="primary" variant="M / Medium">
          <SignalTextFormat type={symbolInfo.signal!.type!} />
        </Text>
        <IconButton size="sm" variant="flat" onClick={closeSignalForm}>
          <IconClose />
        </IconButton>
      </div>
      <TerminalPlaceOrderProvider isSignal>
        <div className="overflow-auto pt-3 scrollbar-hide">
          <PlaceOrderContainer />
        </div>
      </TerminalPlaceOrderProvider>
    </div>
  );
};

export { PlaceOrderSignalBlock };
