import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { IconCross } from "@/domains/icons";
import { LanguagesList } from "@/features/language-switcher/components/languages-list";
import { cn } from "@/shared/styles";
import { centeredDialogContentStyles, Dialog, overlayStyles } from "@/shared/ui";

import type { LanguageDialogProps } from "../types";

const LanguageSwitcherDialog: FC<LanguageDialogProps> = ({ open, languagesInfo, onOpenChange, onLocaleChange }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay className={cn(overlayStyles(), "grid place-items-center overflow-y-auto overflow-x-hidden")}>
          <Dialog.PrimitiveContent
            className={cn(
              centeredDialogContentStyles(),
              "w-full max-w-[368px] rounded-[24px] bg-surface-elevation-2 py-6 shadow outline-none",
            )}
          >
            <div className="mb-6 grid grid-cols-[1fr_auto_1fr] items-center px-4">
              <div />
              <Dialog.Title className="text-center font-gilroy text-[22px] font-semibold leading-normal text-contrast-primary">
                {t("language-switcher.title")}
              </Dialog.Title>
              <Dialog.Close className="ms-auto grid size-9 place-items-center text-contrast-primary outline-none">
                <IconCross />
              </Dialog.Close>
            </div>
            <LanguagesList languagesInfo={languagesInfo} onChange={onLocaleChange} />
          </Dialog.PrimitiveContent>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog>
  );
};

export { LanguageSwitcherDialog };
