import { cva } from "class-variance-authority";
import { type FC } from "react";

import { IconPlus } from "@/domains/icons";

import { useSymbolsContext } from "../contexts/symbols.context";
import { useLayoutContext } from "../layout/context";
import { useCurrentSymbolContext } from "../symbol-info/current-symbol-context";
import { WatchlistDialog } from "../watchlist/dialog";
import { WatchlistPopover } from "../watchlist/popover";
import { ChartSymbolButtonContainer } from "./button/container";

const onSymbolsScroll = (node: HTMLDivElement | null) => {
  if (node) {
    const scrollWidth = node.scrollWidth;
    node.scrollTo({ left: -scrollWidth });
  }
};

const addButtonStyles = cva(
  "grid h-12 place-items-center rounded-[16px] border border-control-border bg-control-bg px-3 outline-none transition-colors hover:bg-control-bg-active data-[state=open]:bg-control-bg-active lg:h-14 lg:rounded-[12px] lg:px-2",
)();

type Props = {
  favoriteSymbols: string[];
  accountId: string;
  setSymbol: ReturnType<typeof useCurrentSymbolContext>["setSymbol"];
  symbolsList: ReturnType<typeof useSymbolsContext>["symbolsList"];
  symbolInfo: ReturnType<typeof useCurrentSymbolContext>["symbolInfo"];
  isMobile: ReturnType<typeof useLayoutContext>["isMobile"];
  watchlistOpen: ReturnType<typeof useLayoutContext>["watchlistOpen"];
  openWatchlist: ReturnType<typeof useLayoutContext>["openWatchlist"];
  watchlistOnChange: ReturnType<typeof useLayoutContext>["watchlistOnChange"];
  closeWatchlist: ReturnType<typeof useLayoutContext>["closeWatchlist"];
};

const ChartSymbols: FC<Props> = ({
  favoriteSymbols,
  accountId,
  setSymbol,
  symbolsList,
  watchlistOpen,
  isMobile,
  watchlistOnChange,
  closeWatchlist,
  symbolInfo,
  openWatchlist,
}) => {
  return (
    <div className="relative flex gap-2 overflow-auto lg:flex-row-reverse">
      <div className="flex gap-2 overflow-auto scrollbar-hide" ref={onSymbolsScroll}>
        {favoriteSymbols.map(symbol => {
          const isActive = symbol === symbolInfo.symbol;

          if (isActive) {
            return (
              <ChartSymbolButtonContainer
                key={symbol}
                symbolInfo={symbolInfo}
                chartFavoritesCount={favoriteSymbols.length}
                accountId={accountId}
                setSymbol={setSymbol}
                openWatchlist={openWatchlist}
                isActive
              />
            );
          }

          return (
            <ChartSymbolButtonContainer
              key={symbol}
              symbolInfo={symbolsList[symbol]!}
              chartFavoritesCount={favoriteSymbols.length}
              accountId={accountId}
              setSymbol={setSymbol}
            />
          );
        })}
      </div>
      {isMobile ? (
        <WatchlistDialog
          watchlistOpen={watchlistOpen}
          watchlistOnChange={watchlistOnChange}
          closeWatchlist={closeWatchlist}
        >
          <button className={addButtonStyles}>
            <IconPlus />
          </button>
        </WatchlistDialog>
      ) : (
        <WatchlistPopover
          watchlistOpen={watchlistOpen}
          watchlistOnChange={watchlistOnChange}
          closeWatchlist={closeWatchlist}
        >
          <button className={addButtonStyles}>
            <IconPlus />
          </button>
        </WatchlistPopover>
      )}
    </div>
  );
};

export { ChartSymbols };
