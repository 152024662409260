import type { FC } from "react";
import { memo } from "react";
import { useTranslation } from "react-i18next";

import { NumberFormat } from "@/app/components";
import type { TerminalSymbolWidgetSummaryScore } from "@/services/openapi";

import { WidgetBlock } from "../../../widgets/block";
import { getIntermediateTermDescription, getLongTermDescription, getShortTermDescription } from "./helpers";
import { InfoRow } from "./info-row";
import { TextBlock } from "./text-block";

type Props = {
  support: number;
  resistance: number;
  priceDecimalScale: number;
  summaryScore: TerminalSymbolWidgetSummaryScore;
};

const TechnicalsSummaryScoreWidget: FC<Props> = ({ resistance, support, priceDecimalScale, summaryScore }) => {
  const { t } = useTranslation();

  return (
    <WidgetBlock
      title={t("terminal.insights.widgets.technicals-summary-score.title")}
      description={t("terminal.insights.widgets.technicals-summary-score.description")}
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <InfoRow
            label={t("terminal.insights.widgets.technicals-summary-score.short.title")}
            description={getShortTermDescription({ t, summary: summaryScore.scoreShort! })}
            summary={summaryScore.scoreShort!}
          />
          <InfoRow
            label={t("terminal.insights.widgets.technicals-summary-score.intermediate.title")}
            description={getIntermediateTermDescription({ t, summary: summaryScore.scoreIntermediate! })}
            summary={summaryScore.scoreIntermediate!}
          />
          <InfoRow
            label={t("terminal.insights.widgets.technicals-summary-score.long.title")}
            description={getLongTermDescription({ t, summary: summaryScore.scoreLong! })}
            summary={summaryScore.scoreLong!}
          />
        </div>
        <div className="h-px w-full bg-contrast-quinary" />
        <div className="grid grid-cols-2">
          <TextBlock
            label={<NumberFormat value={support} decimalScale={priceDecimalScale} />}
            description={t("terminal.insights.widgets.technicals-summary-score.support")}
          />
          <TextBlock
            label={<NumberFormat value={resistance} decimalScale={priceDecimalScale} />}
            description={t("terminal.insights.widgets.technicals-summary-score.resistance")}
          />
        </div>
      </div>
    </WidgetBlock>
  );
};

const Component = memo(TechnicalsSummaryScoreWidget);

export { Component as TechnicalsSummaryScoreWidget };
