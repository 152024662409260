import { cva } from "class-variance-authority";
import { type FC } from "react";

import { Text } from "@/shared/ui";

import type { MarginLevelInfo } from "./helpers";

const getProgressOffset = ({ startPoint, value }: { startPoint: number; value: number }) =>
  Math.max(0, 100 - (value - startPoint) * 2);

const progressContainerStyles = cva(
  "relative h-2 w-full overflow-hidden bg-contrast-quinary first:rounded-s-full last:rounded-e-full",
)();

const progressIndicatorStyles = cva("size-full transition", {
  variants: {
    type: {
      danger: "bg-negative-bg",
      warning: "bg-warning-bg",
      positive: "bg-positive-bg",
      safe: "bg-constant-light-green",
    },
  },
});

type Props = {
  marginLevelInfo: MarginLevelInfo;
  className?: string;
};

const MarginLevelProgress: FC<Props> = ({ marginLevelInfo, className }) => {
  const { type, value } = marginLevelInfo;

  return (
    <div className={className}>
      <div className="grid grid-cols-3 gap-px">
        <div className={progressContainerStyles}>
          <div
            className={progressIndicatorStyles({
              type: type === "safe" ? "positive" : "danger",
            })}
            style={{ transform: `translateX(-${getProgressOffset({ startPoint: 0, value })}%)` }}
          />
        </div>
        <div className={progressContainerStyles}>
          <div
            className={progressIndicatorStyles({
              type: type === "safe" ? "positive" : type === "warning" ? "warning" : void 0,
            })}
            style={{ transform: `translateX(-${getProgressOffset({ startPoint: 50, value })}%)` }}
          />
        </div>
        <div className={progressContainerStyles}>
          <div
            className={progressIndicatorStyles({
              type: type === "safe" ? "safe" : void 0,
            })}
            style={{ transform: `translateX(-${getProgressOffset({ startPoint: 100, value })}%)` }}
          />
        </div>
      </div>
      <div className="mt-1 grid grid-cols-3">
        <Text color="secondary" variant="XS / Regular">
          0%
        </Text>
        <Text className="-translate-x-2" color="secondary" variant="XS / Regular">
          50%
        </Text>
        <Text className="-translate-x-2.5" color="secondary" variant="XS / Regular">
          100%
        </Text>
      </div>
    </div>
  );
};

export { MarginLevelProgress };
