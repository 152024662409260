import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { PaymentWrapper } from "@/features/payment/ui/wrapper/index";
import type { PaymentMethod, TradingAccount } from "@/services/openapi";
import { useMaximumDepositAmountQuery, useMaximumWithdrawAmountQuery, usePaymentInfo } from "@/state/server/payment";
import { useProfileData } from "@/state/server/profile/profile.hooks";

import { AmountStep } from "./step";

type Props = {
  currentAccount: TradingAccount;
  currentPaymentMethod: PaymentMethod;
  currentAmount: string;
  setCurrentAmount: (value: string) => void;
  back: () => void;
  next: () => void;
};

const AmountStepContainer: FC<Props> = ({
  currentAccount,
  currentPaymentMethod,
  next,
  back,
  setCurrentAmount,
  currentAmount,
}) => {
  const { t } = useTranslation();

  const profile = useProfileData();

  const { data: withdrawLimit } = useMaximumWithdrawAmountQuery({
    accountId: currentAccount.id!,
    paymentMethodId: currentPaymentMethod.id!,
    recurringId: currentPaymentMethod.recurringId!,
  });
  const { data: paymentsInfo } = usePaymentInfo();
  const { data: depositLimit } = useMaximumDepositAmountQuery({
    accountId: currentAccount.id,
    paymentMethodId: currentPaymentMethod.id!,
    recurringId: currentPaymentMethod.recurringId!,
  });

  return (
    <PaymentWrapper title={t("payments.payment-method")} onBack={back}>
      {paymentsInfo && depositLimit && withdrawLimit ? (
        <AmountStep
          amountSuggestions={paymentsInfo.depositInfo!.uiAmountsInfo!.amounts![currentAccount.currency!]!}
          currentAccount={currentAccount}
          currentAmount={currentAmount}
          currentLimitBeforeKyc={depositLimit.extendedInfo!.depositCurrentLimitNoKyc!}
          currentPaymentMethod={currentPaymentMethod}
          next={next}
          isKycCompleted={profile.options!.isKycCompleted!}
          setCurrentAmount={setCurrentAmount}
          withdrawLimit={withdrawLimit}
        />
      ) : (
        <PaymentWrapper.Loader />
      )}
    </PaymentWrapper>
  );
};

export { AmountStepContainer };
