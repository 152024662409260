import { type ComponentProps, type FC } from "react";
import { useTranslation } from "react-i18next";

import { TradingTablesPopoverContent } from "../../ui/popover-content";
import { PartialCloseContainer } from "./container";

type Props = ComponentProps<typeof PartialCloseContainer>;

const PartialClosePopoverContent: FC<Props> = props => {
  const { t } = useTranslation();

  return (
    <TradingTablesPopoverContent
      title={t("terminal.orders.partial-close.title")!}
      tooltip={t("terminal.orders.partial-close.description")}
    >
      <PartialCloseContainer {...props} />
    </TradingTablesPopoverContent>
  );
};

export { PartialClosePopoverContent };
