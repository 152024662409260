import { type ReactNode } from "react";

import { Loader } from "@/shared/ui";

type Props<T> = {
  children: (item: T) => ReactNode;
  emptyState: ReactNode;
  isLoading: boolean;
  items: T[];
  bottomSection?: ReactNode;
  action?: ReactNode;
};

const TradingCardsWrapper = <T,>({ action, items, children, emptyState, isLoading, bottomSection }: Props<T>) => {
  if (isLoading) {
    return (
      <div className="mt-10">
        <Loader />
      </div>
    );
  }

  if (items.length === 0) {
    return <div className="mt-10">{emptyState}</div>;
  }

  return (
    <div className="flex flex-col gap-3">
      {action && <div className="mb-1">{action}</div>}
      {items.map(children)}
      {bottomSection}
    </div>
  );
};

export { TradingCardsWrapper };
