import { type FC, memo } from "react";
import { useTranslation } from "react-i18next";

import { useCurrentSymbolContext } from "@/features/terminal/symbol-info/current-symbol-context";
import { Loader } from "@/shared/ui";

import { useLoadClosedPositions } from "../../closed-positions/load-positions.hook";
import { TradingTableEmptyState } from "../../ui/empty-state";
import { TradingTable } from "../table";
import { ClosedPositionsBodyRow } from "./body-row";
import { ClosedPositionsHeaderRow } from "./header-row";

type Props = {
  accountId: string;
  currency: string;
  currencyDecimalScale: number;
  setSymbol: ReturnType<typeof useCurrentSymbolContext>["setSymbol"];
};

const ClosedPositionsTable: FC<Props> = ({ accountId, setSymbol, currency, currencyDecimalScale }) => {
  const { t } = useTranslation();

  const { isLoading, hasNextPage, items, ref } = useLoadClosedPositions({ accountId });

  return (
    <TradingTable
      items={items}
      emptyState={
        <TradingTableEmptyState
          title={t("terminal.empty-state.closed-title")}
          description={t("terminal.empty-state.closed-description")}
        />
      }
      isLoading={isLoading}
      renderHeader={() => <ClosedPositionsHeaderRow currency={currency} />}
      renderRow={position => (
        <ClosedPositionsBodyRow
          key={position.position}
          accountId={accountId}
          position={position}
          setSymbol={setSymbol}
          currencyDecimalScale={currencyDecimalScale}
        />
      )}
      bottomRow={
        hasNextPage ? (
          <TradingTable.Row ref={ref}>
            <TradingTable.Cell colSpan={100}>
              <Loader />
            </TradingTable.Cell>
          </TradingTable.Row>
        ) : null
      }
    />
  );
};

const Component = memo(ClosedPositionsTable);

export { Component as ClosedPositionsTable };
