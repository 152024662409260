import type { FC } from "react";

import { TerminalTableState, useLayoutContext } from "../../layout/context";
import { ClosedPositionsContainer } from "../closed-positions/container";
import { OpenOrdersContainer } from "../open-orders/container";
import { PendingOrdersContainer } from "../pending-orders/container";

const DekstopTradingTablesContainer: FC = () => {
  const { table } = useLayoutContext();

  return (
    <div className="flex min-h-0 grow flex-col bg-surface-canvas">
      {table === TerminalTableState.OPEN && <OpenOrdersContainer />}
      {table === TerminalTableState.PENDING && <PendingOrdersContainer />}
      {table === TerminalTableState.CLOSED_POSITIONS && <ClosedPositionsContainer />}
    </div>
  );
};

export { DekstopTradingTablesContainer };
