import type { ComponentProps, FC } from "react";

import { NumberFormat } from "@/app/components";
import { cn } from "@/shared/styles";

import { calculatePipsSpread, pipsSpreadDecimalScale } from "../../helpers/formulas";
import { PlaceOrderDirection } from "../context";
import { _Button } from "./button";

type Props = Omit<ComponentProps<typeof _Button>, "active"> & {
  className?: string;
};

const DirectionButtons: FC<Props> = ({ direction, className, ...props }) => {
  const { priceDecimalScale } = props;

  return (
    <div className={cn("relative flex", className)}>
      <_Button active={direction === PlaceOrderDirection.DOWN} direction={PlaceOrderDirection.DOWN} {...props} />
      <_Button active={direction === PlaceOrderDirection.UP} direction={PlaceOrderDirection.UP} {...props} />
      <div className="typography-XS-Regular absolute start-1/2 top-1/2 flex h-5 -translate-x-1/2 -translate-y-1/2 items-center rounded-[8px] border border-contrast-quaternary bg-surface-elevation-1 px-2 text-contrast-primary">
        <NumberFormat
          value={calculatePipsSpread({
            priceDecimalScale,
            ask: props.ask,
            bid: props.bid,
          })}
          decimalScale={pipsSpreadDecimalScale}
        />
      </div>
    </div>
  );
};

export { DirectionButtons };
