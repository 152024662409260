import { type FC, memo } from "react";
import { useTranslation } from "react-i18next";

import { TradingAccountType } from "@/services/openapi";
import { NewButton, Popover } from "@/shared/ui";

import type { useLayoutContext } from "../layout/context";

type Props = {
  accountsCount: number;
  accountType: TradingAccountType;
  onDeposit: ReturnType<typeof useLayoutContext>["onDeposit"];
  onRealSwitch: () => void;
  onDemoSwitch: () => void;
  onSwitchAccounts: () => void;
};

const AccountDetailsFooter: FC<Props> = ({
  accountType,
  accountsCount,
  onDeposit,
  onRealSwitch,
  onDemoSwitch,
  onSwitchAccounts,
}) => {
  const { t } = useTranslation();

  const isDemo = accountType === TradingAccountType.Demo;

  return (
    <Popover.Footer>
      <Popover.Close asChild>
        {accountsCount > 2 ? (
          <NewButton onClick={onSwitchAccounts} layout="auto" variant="secondary">
            {t("terminal.account-details.buttons.switch-accounts")}
          </NewButton>
        ) : isDemo ? (
          <NewButton onClick={onRealSwitch} layout="auto" variant="secondary">
            {t("terminal.account-details.buttons.switch-to-real")}
          </NewButton>
        ) : (
          <NewButton onClick={onDemoSwitch} layout="auto" variant="secondary">
            {t("terminal.account-details.buttons.switch-to-demo")}
          </NewButton>
        )}
      </Popover.Close>
      {isDemo && (
        <NewButton onClick={onDeposit} layout="auto" variant="secondary">
          {t("terminal.account-details.buttons.add-funds")}
        </NewButton>
      )}
    </Popover.Footer>
  );
};

const Component = memo(AccountDetailsFooter);

export { Component as AccountDetailsFooter };
