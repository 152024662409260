import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { PnlFormat, PositiveNumberFormat } from "@/app/components";
import { getNumberTextColor } from "@/app/ui/colors";
import { IconChevronDown } from "@/domains/icons";
import { AccountBadge } from "@/entities/accounts/badge";
import { useDisclosure } from "@/hooks/disclosure.hook";
import { AvailabilityStatus, TradingAccountSorting } from "@/services/openapi";
import { cn } from "@/shared/styles";
import { Dialog, DialogSelect, Popover, Text, UnstyledButton } from "@/shared/ui";
import { useAllAccountsQuery } from "@/state/server/accounts";

import { useTerminalAccountSummary } from "../account-summary/context";
import { useTerminalAccountContext } from "../contexts/account.context";
import { useOrdersContext } from "../orders/context";
import { AccountDetailsContent } from "./content";
import { AccountDetailsSelect } from "./select/select";
import { AccountDetailsSkeleton } from "./skeleton";

type Props = {
  centered?: boolean;
};

const AccountDetails: FC<Props> = ({ centered }) => {
  const { t } = useTranslation();

  const { account, setAccount, showBonus } = useTerminalAccountContext();

  const { pnl, currency, currencyDecimalScale } = useTerminalAccountSummary();

  const { openOrders } = useOrdersContext();

  const [selectOpened, { onOpenChange: selectOnOpenChange, open: openSelect }] = useDisclosure();

  const { data: accounts } = useAllAccountsQuery(
    {
      isTerminalEnabledOnly: true,
      status: AvailabilityStatus.Active,
      sorting: TradingAccountSorting.Newest,
    },
    { refetchOnWindowFocus: false },
  );

  const hasOrders = openOrders.length > 0;

  if (!accounts) {
    return <AccountDetailsSkeleton />;
  }

  return (
    <Dialog open={selectOpened} onOpenChange={selectOnOpenChange}>
      <Popover modal>
        <Popover.Trigger asChild>
          <UnstyledButton
            className={cn(
              "inline-flex gap-3 rounded-[12px] px-2 py-2.5 tabular-nums outline-none transition-colors hover:bg-control-bg-hover data-[state=open]:bg-control-bg-hover",
              hasOrders && "py-1",
              centered && "items-center",
            )}
          >
            <AccountBadge type={account.type!} />
            <div className="flex flex-col gap-0.5">
              <div className="flex items-center gap-1">
                <Text
                  variant="M / Medium"
                  color="primary"
                  className="line-clamp-1 lg:line-clamp-none lg:whitespace-nowrap"
                >
                  <PositiveNumberFormat
                    currency={account.currency!}
                    value={account.equity}
                    decimalScale={account.digits}
                  />
                </Text>
                <IconChevronDown className="size-4" />
              </div>
              {hasOrders && (
                <Text variant="XS / Regular" color={getNumberTextColor(pnl) || "secondary"}>
                  <PnlFormat value={pnl} decimalScale={currencyDecimalScale} currency={currency} />
                </Text>
              )}
            </div>
          </UnstyledButton>
        </Popover.Trigger>
        <Popover.Content align="end" titleSize="sm" title={t("terminal.account-details.title")!} centered>
          <AccountDetailsContent
            accounts={accounts}
            currentAccount={account}
            setAccount={setAccount}
            showBonus={showBonus}
            openSelect={openSelect}
            hasOrders={hasOrders}
          />
        </Popover.Content>
      </Popover>
      <DialogSelect.Content title={t("terminal.account-details.select.title")!}>
        <AccountDetailsSelect accounts={accounts} currentAccountId={account.id!} setAccount={setAccount} />
      </DialogSelect.Content>
    </Dialog>
  );
};

export { AccountDetails };
