import { type FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { NewSubmitButton } from "@/app/form/new-submit-button";

import { usePlaceOrderContext } from "../context";

type Props = {
  isPendingOrder: ReturnType<typeof usePlaceOrderContext>["isPendingOrder"];
  isBuyOrder: ReturnType<typeof usePlaceOrderContext>["isBuyOrder"];
  hasNoFreeMargin: ReturnType<typeof usePlaceOrderContext>["hasNoFreeMargin"];
  isDemoAccount: boolean;
};

const PlaceOrderSubmitButton: FC<Props> = ({ hasNoFreeMargin, isBuyOrder, isDemoAccount, isPendingOrder }) => {
  const { t } = useTranslation();

  const label = useMemo(() => {
    if (hasNoFreeMargin) {
      if (isDemoAccount) {
        return t("terminal.place-order.submit-button.deposit-demo");
      }
      return t("terminal.place-order.submit-button.deposit-real");
    }
    if (isPendingOrder) {
      return t("terminal.place-order.submit-button.pending");
    }
    if (isBuyOrder) {
      return t("terminal.place-order.submit-button.market-buy");
    }
    return t("terminal.place-order.submit-button.market-sell");
  }, [hasNoFreeMargin, isBuyOrder, isDemoAccount, isPendingOrder, t]);

  return (
    <NewSubmitButton layout="auto" fullWidth>
      {label}
    </NewSubmitButton>
  );
};

export { PlaceOrderSubmitButton };
