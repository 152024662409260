import type { FC } from "react";
import { useEffect, useMemo } from "react";

import { PnlFormat } from "@/app/components";
import { HookForm, useControlledField, useHookForm } from "@/app/form";
import { NewSubmitButton } from "@/app/form/new-submit-button";
import { getNumberTextColor } from "@/app/ui/colors";
import { formatInputNumberValue, getInputNumberValue } from "@/features/terminal/helpers/formatting";
import { calculateProfitAndLoss } from "@/features/terminal/helpers/formulas";
import type { MergedTerminalSymbol } from "@/features/terminal/helpers/symbols";
import { useTranslation } from "@/hooks/translator.hook";
import { TerminalDealType } from "@/services/openapi";
import { DataList, NewButton, NewNumberInput, Popover } from "@/shared/ui";

import { getModifyOrderRules, getStopLossThresholdValue, getTakeProfitThresholdValue } from "../helpers";
import { ModifyMoreLessDescription } from "../more-less-description";

enum Fields {
  STOP_LOSS = "stopLoss",
}

type FormValues = {
  [Fields.STOP_LOSS]: string;
};

type Props = {
  onSubmit: (values: FormValues) => Promise<unknown>;
  priceDecimalScale: number;
  stopLoss: number | undefined;
  ask: number;
  bid: number;
  orderType: TerminalDealType;
  baseCurrency: string;
  quoteCurrency: string;
  contractSize: number;
  currency: string;
  volume: number;
  openPrice: number;
  currencyDecimalScale: number;
  symbols: MergedTerminalSymbol[];
  profitRate?: number;
};

const ModifyStopLossForm: FC<Props> = ({
  onSubmit,
  priceDecimalScale,
  stopLoss: stopLossProp,
  ask,
  bid,
  orderType,
  baseCurrency,
  contractSize,
  currency,
  openPrice,
  volume,
  quoteCurrency,
  currencyDecimalScale,
  profitRate,
  symbols,
}) => {
  const { t } = useTranslation();

  const form = useHookForm<FormValues>({
    defaultValues: {
      [Fields.STOP_LOSS]: formatInputNumberValue(stopLossProp, priceDecimalScale),
    },
  });
  const { watch, formState, trigger, control } = form;
  const { isValid, touchedFields } = formState;

  const isTouched = touchedFields[Fields.STOP_LOSS];

  const { stopLoss: sl } = watch();
  const stopLoss = getInputNumberValue(sl);

  const takeProfitThresholdValue = useMemo(
    () => getTakeProfitThresholdValue({ ask, bid, priceDecimalScale, openPrice, orderType }),
    [orderType, priceDecimalScale, ask, bid, openPrice],
  );

  const stopLossThresholdValue = useMemo(
    () => getStopLossThresholdValue({ ask, bid, priceDecimalScale, openPrice, orderType, takeProfitThresholdValue }),
    [orderType, ask, bid, takeProfitThresholdValue, openPrice, priceDecimalScale],
  );

  useEffect(() => {
    if (isTouched) {
      trigger(Fields.STOP_LOSS);
    }
  }, [takeProfitThresholdValue, isTouched, stopLoss]);

  const [field, { invalid, pending }] = useControlledField<FormValues>({
    name: Fields.STOP_LOSS,
    control,
    rules: getModifyOrderRules({
      thresholdValue: stopLossThresholdValue,
      type: "stopLoss",
      orderType,
      value: stopLoss,
    }),
  });

  const pnl = useMemo(() => {
    if (!stopLoss) {
      return null;
    }

    return calculateProfitAndLoss({
      type: orderType,
      volume,
      openPrice,
      accountCurrency: currency,
      currentPrice: stopLoss,
      baseCurrency,
      quoteCurrency,
      contractSize,
      symbols,
      profitRate,
    });
  }, [
    orderType,
    volume,
    openPrice,
    currency,
    baseCurrency,
    quoteCurrency,
    contractSize,
    symbols,
    profitRate,
    stopLoss,
  ]);

  return (
    <HookForm form={form} onSubmit={onSubmit}>
      <NewNumberInput
        autoFocus
        clearable
        decimalScale={priceDecimalScale}
        pending={pending}
        invalid={invalid}
        placeholder={t("terminal.orders.stop-loss.placeholder")!}
        descriptor={
          <ModifyMoreLessDescription
            decimalScale={priceDecimalScale}
            thresholdValue={stopLossThresholdValue}
            orderType={orderType}
            onChange={field.onChange}
            type="stopLoss"
          />
        }
        {...field}
      />
      <DataList className="mt-3">
        <DataList.Item
          label={<DataList.Label>{t("terminal.orders.stop-loss.potential-loss")}</DataList.Label>}
          value={
            isValid && !!pnl ? (
              <DataList.Value color={getNumberTextColor(pnl)}>
                <PnlFormat value={pnl} decimalScale={currencyDecimalScale} currency={currency} />
              </DataList.Value>
            ) : (
              <DataList.Value>—</DataList.Value>
            )
          }
        />
      </DataList>
      <Popover.Footer>
        <NewSubmitButton size="sm" layout="auto">
          {t("terminal.orders.stop-loss.action")}
        </NewSubmitButton>
        <Popover.Close asChild>
          <NewButton variant="secondary" size="sm" layout="auto">
            {t("button.cancel")}
          </NewButton>
        </Popover.Close>
      </Popover.Footer>
    </HookForm>
  );
};

export { ModifyStopLossForm };
