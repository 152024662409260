import { type FC, useCallback, useMemo } from "react";

import { getRealDemoAccounts } from "@/entities/accounts/helpers";
import { type TradingAccount } from "@/services/openapi";

import { useTerminalAccountSummary } from "../account-summary/context";
import { useTerminalAccountContext } from "../contexts/account.context";
import { useLayoutContext } from "../layout/context";
import { AccountDetailsBonusRiskBlock } from "./bonus-risk/block";
import { AccountsDetailsDataList } from "./data-list";
import { AccountDetailsFooter } from "./footer";
import { AccountDetailsMarginLevelBlock } from "./margin-level/block";

type Props = {
  accounts: TradingAccount[];
  currentAccount: TradingAccount;
  showBonus: boolean;
  hasOrders: boolean;
  setAccount: ReturnType<typeof useTerminalAccountContext>["setAccount"];
  openSelect: () => void;
};

const AccountDetailsContent: FC<Props> = ({
  accounts,
  currentAccount,
  showBonus,
  hasOrders,
  setAccount,
  openSelect,
}) => {
  const { onDeposit } = useLayoutContext();

  const { currency, equity, marginLevel, margin, pnl, currencyDecimalScale, marginFree, credit, balance, type } =
    useTerminalAccountSummary();

  const { demoAccounts, realAccounts } = useMemo(() => getRealDemoAccounts(accounts), [accounts]);

  const onRealSwitch = useCallback(() => {
    const realAccount = realAccounts[0];
    if (realAccount) {
      setAccount(realAccount.id!);
    }
  }, [realAccounts, setAccount]);

  const onDemoSwitch = useCallback(() => {
    const demoAccount = demoAccounts[0];
    if (demoAccount) {
      setAccount(demoAccount.id!);
    }
  }, [demoAccounts, setAccount]);

  return (
    <>
      <div className="flex flex-col gap-4">
        {showBonus ? (
          <AccountDetailsBonusRiskBlock
            credit={credit}
            currency={currency}
            currencyDecimalScale={currencyDecimalScale}
            equity={equity}
            onDeposit={onDeposit}
          />
        ) : (
          <AccountDetailsMarginLevelBlock marginLevel={marginLevel} hasOrders={hasOrders} />
        )}
        <AccountsDetailsDataList
          showBonus={showBonus}
          accountCurrency={currency}
          equity={equity}
          balance={balance}
          credit={credit}
          margin={margin}
          marginFree={marginFree}
          pnl={pnl}
          currencyDecimalScale={currencyDecimalScale}
          marginLevel={marginLevel}
          type={type}
        />
      </div>
      <AccountDetailsFooter
        onDeposit={onDeposit}
        accountType={currentAccount.type!}
        accountsCount={accounts.length}
        onDemoSwitch={onDemoSwitch}
        onRealSwitch={onRealSwitch}
        onSwitchAccounts={openSelect}
      />
    </>
  );
};

export { AccountDetailsContent };
