import type { ComponentProps, FC } from "react";
import { useCallback } from "react";

import { getInputNumberValue } from "@/features/terminal/helpers/formatting";
import { TerminalDealType } from "@/services/openapi";
import { useModifyTerminalOrderMutation } from "@/state/server/terminal";

import { OpenPriceForm } from "./form";

type Props = {
  accountId: string;
  openPrice: number;
  orderId: number;
  priceDecimalScale: number;
  takeProfit: number | undefined;
  stopLoss: number | undefined;
  ask: number;
  bid: number;
  orderType: TerminalDealType;
  onClose: () => void;
};

const OpenPriceContainer: FC<Props> = ({
  accountId,
  orderId,
  onClose,
  priceDecimalScale,
  stopLoss,
  takeProfit,
  ask,
  bid,
  orderType,
  openPrice,
}) => {
  const { mutateAsync: modifyOrder } = useModifyTerminalOrderMutation();

  const handleSubmit: ComponentProps<typeof OpenPriceForm>["onSubmit"] = useCallback(
    ({ openingPrice }) => {
      return modifyOrder(
        {
          tradingAccountId: accountId,
          id: orderId,
          terminalModifyOrderRequest: { stopLoss, takeProfit, price: getInputNumberValue(openingPrice) },
        },
        {
          onSuccess: () => {
            onClose();
          },
        },
      );
    },
    [modifyOrder, accountId, orderId, onClose, takeProfit, stopLoss],
  );

  return (
    <OpenPriceForm
      onSubmit={handleSubmit}
      priceDecimalScale={priceDecimalScale}
      ask={ask}
      bid={bid}
      orderType={orderType}
      openPrice={openPrice}
    />
  );
};

export { OpenPriceContainer };
