import type { TextColor } from "@/shared/ui";

// TODO: rename
const getNumberColorClassname = (value: number): string | undefined => {
  if (value > 0) return "text-positive-text";
  if (value < 0) return "text-negative-text";
};

// TODO: rename
const getNumberTextColor = (value: number): TextColor | undefined => {
  if (value > 0) return "positive";
  if (value < 0) return "negative";
};

export { getNumberColorClassname, getNumberTextColor };
