import { cva } from "class-variance-authority";
import type { ComponentPropsWithoutRef, ElementRef, FC, ReactNode } from "react";
import { forwardRef } from "react";

import { IconCross } from "@/domains/icons";
import { useScreenSize } from "@/hooks/screen-size.hook";
import { cn } from "@/shared/styles";
import { centeredDialogContentStyles, overlayStyles, ScrollArea } from "@/shared/ui";

import { Dialog } from "./dialog";

const descriptionStyles = cva("typography-S-Regular mb-6 mt-4 text-center md:mb-8")();

const DescriptionStrong: FC<ComponentPropsWithoutRef<"span">> = ({ className, ...props }) => {
  return <span className={cn(descriptionStyles, "font-semibold", className)} {...props} />;
};

type Props = ComponentPropsWithoutRef<typeof Dialog.PrimitiveContent> & {
  title: string;
  image?: string;
  icon?: ReactNode;
  description?: ReactNode;
  footer?: ReactNode;
  scrollAreaClassname?: string;
};

const Content = forwardRef<ElementRef<typeof Dialog.PrimitiveContent>, Props>(
  ({ children, title, image, className, description, footer, scrollAreaClassname, icon, ...props }, forwardedRef) => {
    const { isMobile } = useScreenSize();

    return (
      <Dialog.Portal>
        <Dialog.Overlay className={overlayStyles({ hasAnimation: !isMobile })} />
        <Dialog.PrimitiveContent
          className={cn(
            centeredDialogContentStyles({ hasAnimation: !isMobile }),
            "flex h-full w-full flex-col overflow-auto bg-bg font-roboto shadow outline-none md:h-auto md:max-h-[calc(100dvh-112px)] md:max-w-[640px] md:rounded-[24px]",
            className,
          )}
          {...props}
          ref={forwardedRef}
        >
          <div className="flex justify-end bg-bg p-4 md:p-6 md:pb-2">
            <Dialog.Close>
              <IconCross />
            </Dialog.Close>
          </div>
          <ScrollArea scrollbars="vertical" className={scrollAreaClassname} viewportClassname="px-4 pb-16 md:px-14">
            <div className="mb-6 flex justify-center md:mb-8">
              {image && <img alt="" src={image} className="h-[145px] w-[145px]" loading="lazy" />}
              {icon && icon}
            </div>
            <Dialog.Title className="typography-2XL-Medium text-center text-contrast-primary">{title}</Dialog.Title>

            <div className={descriptionStyles}>{description}</div>

            {children}
          </ScrollArea>

          {footer && <div className="absolute bottom-0 end-0 start-0 px-4 pb-4 md:px-14 md:pb-16">{footer}</div>}
        </Dialog.PrimitiveContent>
      </Dialog.Portal>
    );
  },
);

const Component = Object.assign(Content, { DescriptionStrong });

export { Component as WideDialogContent };
