import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { NumberFormat, PositiveNumberFormat } from "@/app/components";
import { IconChevronRight, IconWarningCircle } from "@/domains/icons";
import { type BonusRisk } from "@/entities/bonuses/helpers";
import { DataList, Dialog, InfoBlock } from "@/shared/ui";

import type { useLayoutContext } from "../../layout/context";

type Props = {
  equity: number;
  credit: number;
  currencyDecimalScale: number;
  currency: string;
  bonusRisk: BonusRisk;
  onDeposit: ReturnType<typeof useLayoutContext>["onDeposit"];
};

const BonusRiskDialog: FC<Props> = ({ credit, currency, currencyDecimalScale, equity, bonusRisk, onDeposit }) => {
  const { t } = useTranslation();

  return (
    <Dialog.Content>
      <Dialog.Header title={t("terminal.account-details.bonus-risk.title")} />
      <div className="flex flex-col gap-4">
        <DataList>
          <DataList.Item
            label={<DataList.Label>{t("terminal.account-details.equity")}</DataList.Label>}
            value={
              <DataList.Value>
                <PositiveNumberFormat value={equity} decimalScale={currencyDecimalScale} currency={currency} />
              </DataList.Value>
            }
          />
          <DataList.Item
            label={<DataList.Label>{t("terminal.account-details.bonus")}</DataList.Label>}
            value={
              <DataList.Value>
                <NumberFormat value={credit} decimalScale={currencyDecimalScale} currency={currency} />
              </DataList.Value>
            }
          />
          <DataList.Item
            label={<DataList.Label>{t("terminal.account-details.bonus-risk.title")}</DataList.Label>}
            value={
              <DataList.Value
                variant="M / Medium"
                color={bonusRisk.type === "danger" ? "negative" : bonusRisk.type === "warning" ? "warning" : "positive"}
              >
                <NumberFormat value={bonusRisk.value} suffix="%" />
              </DataList.Value>
            }
          />
        </DataList>
        {bonusRisk.type === "safe" && (
          <InfoBlock
            variant="accent"
            icon={<IconWarningCircle />}
            text={t("terminal.account-details.bonus-risk.safe-description")}
          />
        )}
        {bonusRisk.type === "warning" && (
          <InfoBlock
            variant="warning"
            icon={<IconWarningCircle />}
            text={t("terminal.account-details.bonus-risk.warning-description")}
          />
        )}
        {bonusRisk.type === "danger" && (
          <InfoBlock
            variant="negative"
            icon={<IconWarningCircle />}
            text={t("terminal.account-details.bonus-risk.danger-description")}
          >
            <Dialog.Close asChild>
              <InfoBlock.Action onClick={onDeposit} icon={<IconChevronRight />}>
                {t("terminal.account-details.bonus-risk.danger-action")}
              </InfoBlock.Action>
            </Dialog.Close>
          </InfoBlock>
        )}
      </div>
    </Dialog.Content>
  );
};

export { BonusRiskDialog };
