import { type FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { NumberFormat } from "@/app/components";
import { IconHint } from "@/domains/icons";
import { getBonusRisk } from "@/entities/bonuses/helpers";
import { Dialog, LabelButton, Progress } from "@/shared/ui";

import type { useLayoutContext } from "../../layout/context";
import { AccountDetailsInfoBlock } from "../ui/info-block";
import { BonusRiskDialog } from "./dialog";

type Props = {
  equity: number;
  credit: number;
  currencyDecimalScale: number;
  currency: string;
  onDeposit: ReturnType<typeof useLayoutContext>["onDeposit"];
};

const AccountDetailsBonusRiskBlock: FC<Props> = ({ credit, currency, currencyDecimalScale, equity, onDeposit }) => {
  const { t } = useTranslation();

  const bonusRisk = useMemo(() => getBonusRisk({ equity, credit }), [equity, credit]);

  return (
    <AccountDetailsInfoBlock
      title={t("terminal.account-details.bonus-risk.title")}
      subText={<NumberFormat value={bonusRisk.value} suffix="%" />}
      subTextColor={bonusRisk.type === "danger" ? "negative" : bonusRisk.type === "warning" ? "warning" : "positive"}
      endBlock={
        <Dialog>
          <Dialog.Trigger asChild>
            <LabelButton
              variant={bonusRisk.type === "danger" ? "negative" : bonusRisk.type === "warning" ? "warning" : "positive"}
              icon={<IconHint />}
            >
              {bonusRisk.type === "danger"
                ? t("terminal.account-details.bonus-risk.danger")
                : bonusRisk.type === "warning"
                  ? t("terminal.account-details.bonus-risk.warning")
                  : t("terminal.account-details.bonus-risk.safe")}
            </LabelButton>
          </Dialog.Trigger>
          <BonusRiskDialog
            bonusRisk={bonusRisk}
            credit={credit}
            currency={currency}
            currencyDecimalScale={currencyDecimalScale}
            equity={equity}
            onDeposit={onDeposit}
          />
        </Dialog>
      }
    >
      <Progress
        className="mt-3"
        variant={bonusRisk.type === "danger" ? "negative" : bonusRisk.type === "warning" ? "warning" : "positive"}
        value={bonusRisk.value}
      />
    </AccountDetailsInfoBlock>
  );
};

export { AccountDetailsBonusRiskBlock };
