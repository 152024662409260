import { useEffect } from "react";

import { setAutoPricescale } from "../config/pricescale";

// https://www.tradingview.com/charting-library-docs/latest/api/interfaces/Charting_Library.IChartWidgetApi/#onintervalchanged

const useAutoscale = () => {
  useEffect(() => {
    window.tvWidget
      .activeChart()
      .onIntervalChanged()
      .subscribe(null, () => {
        setAutoPricescale(window.tvWidget);
      });
  }, []);
};

export { useAutoscale };
