import Big from "big.js";

type MarginLevelInfo = {
  value: number;
  type: "danger" | "warning" | "safe";
};

const getMarginLevelInfo = ({ marginLevel }: { marginLevel: number }): MarginLevelInfo => {
  const level = new Big(marginLevel).round(0).toNumber();

  if (level <= 50) {
    return {
      value: level,
      type: "danger",
    };
  }

  if (level <= 100) {
    return {
      value: level,
      type: "warning",
    };
  }

  return {
    value: level,
    type: "safe",
  };
};

export { getMarginLevelInfo };
export type { MarginLevelInfo };
