import { type ComponentProps, type FC } from "react";
import { useTranslation } from "react-i18next";

import { TradingTablesPopoverContent } from "../../ui/popover-content";
import { ModifyOrderTakeProfitContainer } from "./container";

type Props = ComponentProps<typeof ModifyOrderTakeProfitContainer>;

const TakeProfitPopoverContent: FC<Props> = props => {
  const { t } = useTranslation();

  return (
    <TradingTablesPopoverContent
      title={t("terminal.orders.take-profit.title")!}
      tooltip={t("terminal.orders.take-profit.description")}
    >
      <ModifyOrderTakeProfitContainer {...props} />
    </TradingTablesPopoverContent>
  );
};

export { TakeProfitPopoverContent };
