import type { FC, ReactNode } from "react";

import { Text } from "@/shared/ui";

type Props = {
  label: ReactNode;
  description: ReactNode;
};

const TextBlock: FC<Props> = ({ label, description }) => (
  <div className="flex flex-col gap-2">
    <Text color="primary" variant="M / Medium">
      {label}
    </Text>
    <Text color="secondary" variant="M / Regular">
      {description}
    </Text>
  </div>
);

export { TextBlock };
