import { type FC } from "react";

import { NumberFormat } from "@/app/components";
import type { MergedTerminalSymbol } from "@/features/terminal/helpers/symbols";
import { useAddChartSymbol } from "@/features/terminal/hooks/add-chart-symbol.hook";
import { useLayoutContext } from "@/features/terminal/layout/context";
import { SignalTextFormat } from "@/features/terminal/signal/text-format";
import { useCurrentSymbolContext } from "@/features/terminal/symbol-info/current-symbol-context";
import { SymbolIcon } from "@/features/terminal/symbols/icon";
import { SymbolPriceChange } from "@/features/terminal/symbols/price-change";
import { useHover } from "@/hooks/hover.hook";
import { Text } from "@/shared/ui";
import { IconTrendSignals } from "@/shared/ui/icons";

import { calculateLeverage } from "../../helpers/formulas";
import { usePriceChange } from "../../symbols/helpers";
import { LeverageBadge } from "../../ui/leverage-badge";
import { WatchlistFavoriteButton } from "../favorite-button";

type Props = {
  symbolInfo: MergedTerminalSymbol;
  accountId: string;
  leverage: number;
  isMobile: boolean;
  currentSymbol: ReturnType<typeof useCurrentSymbolContext>["currentSymbol"];
  watchlistSelectedSymbol: ReturnType<typeof useLayoutContext>["watchlistSelectedSymbol"];
  setSymbol: ReturnType<typeof useCurrentSymbolContext>["setSymbol"];
  onClose: () => void;
};

const WatchlistSymbolRow: FC<Props> = ({
  symbolInfo,
  accountId,
  onClose,
  setSymbol,
  currentSymbol,
  watchlistSelectedSymbol,
  isMobile,
  leverage,
}) => {
  const {
    symbol,
    name,
    priceDecimalScale,
    priceBid,
    priceLast24H,
    signal,
    isFavorite,
    marginRateInitialMarketBuy,
    type,
  } = symbolInfo;

  const isSelected = symbol === currentSymbol;

  const { ref, hovered } = useHover();

  const { addChartSymbol } = useAddChartSymbol({
    setSymbol,
    accountId,
    replaceSymbol: !!watchlistSelectedSymbol,
    callback: onClose,
  });

  const priceChange = usePriceChange({ bid: priceBid, priceLast24H });

  return (
    <div
      onClick={() => addChartSymbol(symbol!)}
      role="button"
      data-selected={isSelected}
      className="flex h-full w-full items-center bg-card-bg outline-none transition-colors hover:bg-card-bg-hover data-[selected=true]:bg-card-bg-active"
      ref={ref}
    >
      <div className="px-4">
        {hovered && !isMobile ? (
          <WatchlistFavoriteButton accountId={accountId} isFavorite={isFavorite!} symbol={symbol!} />
        ) : (
          <SymbolIcon symbol={symbol!} size="xl" />
        )}
      </div>
      <div className="relative flex h-full grow items-center justify-between gap-2 pe-2 after:absolute after:bottom-0 after:end-0 after:start-0 after:h-px after:bg-contrast-quinary lg:pe-4">
        <div className="flex grow flex-col gap-1">
          <div className="flex items-center justify-between gap-2">
            <div className="flex items-center gap-1">
              <Text variant="S / Medium" color="primary" className="line-clamp-1">
                {name}
              </Text>
              <LeverageBadge
                leverage={calculateLeverage({
                  leverage,
                  marginRateInitialMarketBuy: marginRateInitialMarketBuy!,
                  instrumentType: type!,
                })}
              />
            </div>
            <Text variant="S / Medium" color="primary" align="end">
              <NumberFormat value={priceBid} decimalScale={priceDecimalScale} />
            </Text>
          </div>

          <div className="flex items-center justify-between">
            {signal ? (
              <div className="flex items-center gap-0.5 text-contrast-secondary">
                <IconTrendSignals className="size-4" />
                <Text variant="XS / Medium" color="primary">
                  <SignalTextFormat hasAccentText type={signal.type!} />
                </Text>
              </div>
            ) : (
              <Text variant="S Compact / Regular" color="secondary">
                {symbol}
              </Text>
            )}
            {priceChange && (
              <SymbolPriceChange priceChange={priceChange}>
                {({ value, icon }) => (
                  <div className="flex items-center">
                    {icon}
                    <Text color={priceChange.color || "secondary"} variant="S / Regular">
                      {value}
                    </Text>
                  </div>
                )}
              </SymbolPriceChange>
            )}
          </div>
        </div>
        {isMobile && <WatchlistFavoriteButton accountId={accountId} isFavorite={isFavorite!} symbol={symbol!} />}
      </div>
    </div>
  );
};

export { WatchlistSymbolRow };
