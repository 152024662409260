import { type FC } from "react";

import { useSignalContext } from "../../signal/context";
import { useCurrentSymbolContext } from "../../symbol-info/current-symbol-context";
import { PlaceOrderSignalBlock } from "./signal-block";
import { PlaceOrderTabs } from "./tabs";

const DesktopPlaceOrderBlock: FC = () => {
  const { currentSymbol } = useCurrentSymbolContext();

  const { signalFormOpened } = useSignalContext();

  return (
    <div key={currentSymbol} className="flex min-h-0 grow flex-col bg-surface-canvas">
      {signalFormOpened ? <PlaceOrderSignalBlock /> : <PlaceOrderTabs />}
    </div>
  );
};

export { DesktopPlaceOrderBlock };
