import { Flex } from "@/components/flex";
import { styled } from "@/styles";

import { iconEnd, iconStart } from "../mixins/input";

const Text = styled("span", {
  fontFamily: "$aeonik",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "20px",
});

export const Button = styled("button", {
  position: "relative",
  padding: "10px",
  paddingLeft: "45px",
  textAlign: "left",
  maxHeight: "46px",
  transition: "all 0.15s ease",
  borderRadius: "16px",
  border: "1px solid $gray",
  background: "$inputBgDefault",

  color: "$textMain",

  fontFamily: "$aeonik",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "20px",

  "&:hover": {
    background: "$inputBgHover",
  },

  "&[aria-expanded='true']": { borderColor: "$black" },
});
export const IconStart = styled(iconStart, {});
export const IconEnd = styled(iconEnd, {});

export const CountryValue = styled(Text, {
  width: "40px",
  color: "$textSecondary",
});

export const CountryName = styled(Text, {
  color: "$textMain",

  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",

  variants: {
    selected: {
      true: {
        paddingRight: "36px",
      },
    },
  },
});

export const ItemContent = styled(Flex, {
  flexShrink: 0,
  alignItems: "center",
  gap: "12px",
  marginRight: "4px",
});

export const Icon = styled("div", {
  position: "absolute",
  right: "16px",
});
