import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { NumberFormat, PnlFormat, PositiveNumberFormat } from "@/app/components";
import { getNumberTextColor } from "@/app/ui/colors";
import { TooltipQuestionIcon } from "@/components/icons";
import { TradingAccountType } from "@/services/openapi";
import { DataList, Tooltip } from "@/shared/ui";

type Props = {
  showBonus: boolean;
  accountCurrency: string;
  equity: number;
  marginLevel: number;
  margin: number;
  pnl: number;
  currencyDecimalScale: number;
  marginFree: number;
  credit: number;
  balance: number;
  type: TradingAccountType;
};

const AccountsDetailsDataList: FC<Props> = ({
  showBonus,
  accountCurrency,
  equity,
  marginLevel,
  margin,
  pnl,
  currencyDecimalScale,
  marginFree,
  credit,
  balance,
  type,
}) => {
  const { t } = useTranslation();

  return (
    <DataList className="px-2">
      <DataList.Item
        label={<DataList.Label>{t("terminal.account-details.equity")}</DataList.Label>}
        value={
          <DataList.Value>
            <PositiveNumberFormat value={equity} decimalScale={currencyDecimalScale} currency={accountCurrency} />
          </DataList.Value>
        }
      />
      {type === TradingAccountType.Real && (
        <DataList.Item
          label={<DataList.Label>{t("terminal.account-details.balance")}</DataList.Label>}
          value={
            <DataList.Value>
              <PositiveNumberFormat value={balance} decimalScale={currencyDecimalScale} currency={accountCurrency} />
            </DataList.Value>
          }
        />
      )}
      {showBonus && (
        <DataList.Item
          label={<DataList.Label>{t("terminal.account-details.bonus")}</DataList.Label>}
          value={
            <DataList.Value>
              <NumberFormat value={credit} decimalScale={currencyDecimalScale} currency={accountCurrency} />
            </DataList.Value>
          }
        />
      )}
      <DataList.Item
        label={<DataList.Label>{t("terminal.account-details.pnl")}</DataList.Label>}
        value={
          <DataList.Value color={getNumberTextColor(pnl)}>
            <PnlFormat value={pnl} decimalScale={currencyDecimalScale} currency={accountCurrency} />
          </DataList.Value>
        }
      />
      <DataList.Item
        label={<DataList.Label>{t("terminal.account-details.margin")}</DataList.Label>}
        value={
          <DataList.Value>
            <NumberFormat value={margin} decimalScale={currencyDecimalScale} currency={accountCurrency} />
          </DataList.Value>
        }
      />
      <DataList.Item
        label={<DataList.Label>{t("terminal.account-details.free-margin")}</DataList.Label>}
        value={
          <DataList.Value>
            <PositiveNumberFormat value={marginFree} decimalScale={currencyDecimalScale} currency={accountCurrency} />
          </DataList.Value>
        }
      />
      <DataList.Item
        label={
          <DataList.Label>
            <div className="flex items-center gap-1">
              {t("terminal.account-details.margin-level.title")}
              {showBonus && (
                <Tooltip content={t("terminal.account-details.margin-level.description")}>
                  <TooltipQuestionIcon size="sm" />
                </Tooltip>
              )}
            </div>
          </DataList.Label>
        }
        value={
          <DataList.Value>
            {marginLevel !== 0 ? <NumberFormat value={marginLevel} decimalScale={0} suffix="%" /> : "–"}
          </DataList.Value>
        }
      />
    </DataList>
  );
};

export { AccountsDetailsDataList };
