import type { FC } from "react";

import { NumberFormat, PositiveNumberFormat } from "@/app/components";
import { useTranslation } from "@/hooks/translator.hook";
import { Text } from "@/shared/ui";

import { NetworkConnection } from "./network-connection";

const LabeledValue: FC<{ label: string; value: React.ReactNode }> = ({ label, value }) => {
  return (
    <div className="flex items-center gap-1">
      <Text as="dt" variant="XS / Regular" color="secondary">
        {label}
      </Text>
      <Text as="dd" className="tabular-nums" variant="XS / Regular" color="primary">
        {value}
      </Text>
    </div>
  );
};

type Props = {
  equity: number;
  marginLevel: number;
  currency: string;
  currencyDecimalScale: number;
  marginFree: number;
  margin: number;
};

const AccountSummary: FC<Props> = ({ currency, equity, marginLevel, currencyDecimalScale, marginFree, margin }) => {
  const { t } = useTranslation();

  return (
    <dl className="flex h-7 items-center justify-end gap-4 bg-surface-canvas px-4">
      <LabeledValue
        label={t("terminal.account-summary.equity")}
        value={<PositiveNumberFormat value={equity} currency={currency} decimalScale={currencyDecimalScale} />}
      />
      <LabeledValue
        label={t("terminal.account-summary.margin")}
        value={<NumberFormat value={margin} currency={currency} decimalScale={currencyDecimalScale} />}
      />
      <LabeledValue
        label={t("terminal.account-summary.free-margin")}
        value={<PositiveNumberFormat value={marginFree} currency={currency} decimalScale={currencyDecimalScale} />}
      />
      <LabeledValue
        label={t("terminal.account-summary.margin-level")}
        value={marginLevel !== 0 ? <NumberFormat value={marginLevel} decimalScale={0} suffix="%" /> : "–"}
      />
      <NetworkConnection />
    </dl>
  );
};

export { AccountSummary };
