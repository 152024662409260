import { type FC, memo } from "react";

import { NumberFormat } from "@/app/components";
import type { PendingOrder } from "@/features/terminal/orders/extended-orders.context";
import { terminalFormatDate } from "@/features/terminal/place-order/place-order.helpers";
import { useTranslation } from "@/hooks/translator.hook";
import { DataList, NewButton, Text } from "@/shared/ui";

import { OpenPricePopoverContent } from "../../modify-order/open-price/popover-content";
import { StopLossPopoverContent } from "../../modify-order/stop-loss/popover-content";
import { TakeProfitPopoverContent } from "../../modify-order/take-profit/popover-content";
import { CancelPendingOrderButton } from "../../pending-orders/cancel/cancel-button";
import { ModifyOrderButton } from "../../ui/modify-order/button";
import { OrderTypeFormat } from "../../ui/order-type-format";
import { TradingCard } from "../card";

type Props = {
  order: PendingOrder;
  accountId: string;
  currency: string;
  currencyDecimalScale: number;
};

const PendingOrderCard: FC<Props> = ({ order, accountId, currency, currencyDecimalScale }) => {
  const { t } = useTranslation();

  const {
    symbol,
    type,
    volume,
    price,
    stopLoss,
    takeProfit,
    id,
    date,
    ask,
    bid,
    currentPrice,
    volumeDecimalScale,
    priceDecimalScale,
    baseCurrency,
    contractSize,
    quoteCurrency,
    margin,
  } = order;

  return (
    <TradingCard
      header={
        <TradingCard.Header
          type={type}
          volume={<NumberFormat value={volume} decimalScale={volumeDecimalScale} />}
          endBottomSection={<>{price ? <NumberFormat value={price} decimalScale={priceDecimalScale} /> : "—"}</>}
          symbol={symbol}
          endTopSection={
            <Text variant="S / Regular" color="primary">
              {t("terminal.open-price")}
            </Text>
          }
        />
      }
      body={
        <DataList>
          <DataList.Item
            label={<DataList.Label>{t("common.type")}</DataList.Label>}
            value={
              <DataList.Value>
                <OrderTypeFormat type={type} />
              </DataList.Value>
            }
          />
          <DataList.Item
            label={<DataList.Label>{t("terminal.volume-lot")}</DataList.Label>}
            value={
              <DataList.Value>
                <NumberFormat value={volume} decimalScale={volumeDecimalScale} />
              </DataList.Value>
            }
          />
          <DataList.Item
            label={<DataList.Label>{t("terminal.margin-currency", { currency: currency })}</DataList.Label>}
            value={
              <DataList.Value>
                <NumberFormat value={margin} decimalScale={currencyDecimalScale} />
              </DataList.Value>
            }
          />
          <DataList.Item
            label={<DataList.Label>{t("terminal.open-price")}</DataList.Label>}
            value={
              <DataList.Value>
                <ModifyOrderButton value={<NumberFormat value={price} decimalScale={priceDecimalScale} />}>
                  {onClose => (
                    <OpenPricePopoverContent
                      onClose={onClose}
                      openPrice={price}
                      orderType={type}
                      orderId={id}
                      priceDecimalScale={priceDecimalScale}
                      stopLoss={stopLoss}
                      takeProfit={takeProfit}
                      ask={ask}
                      bid={bid}
                      accountId={accountId}
                    />
                  )}
                </ModifyOrderButton>
              </DataList.Value>
            }
          />
          <DataList.Item
            label={<DataList.Label>{t("terminal.current-price")}</DataList.Label>}
            value={
              <DataList.Value>
                {currentPrice ? <NumberFormat value={currentPrice} decimalScale={priceDecimalScale} /> : "—"}
              </DataList.Value>
            }
          />
          <DataList.Item
            label={<DataList.Label>{t("terminal.take-profit")}</DataList.Label>}
            value={
              <DataList.Value>
                <ModifyOrderButton
                  value={takeProfit ? <NumberFormat value={takeProfit} decimalScale={priceDecimalScale} /> : null}
                >
                  {onClose => (
                    <TakeProfitPopoverContent
                      onClose={onClose}
                      openPrice={price}
                      orderType={type}
                      orderId={id}
                      priceDecimalScale={priceDecimalScale}
                      stopLoss={stopLoss}
                      takeProfit={takeProfit}
                      ask={ask}
                      bid={bid}
                      accountId={accountId}
                      baseCurrency={baseCurrency}
                      quoteCurrency={quoteCurrency}
                      contractSize={contractSize}
                      volume={volume}
                      currency={currency}
                      currencyDecimalScale={currencyDecimalScale}
                    />
                  )}
                </ModifyOrderButton>
              </DataList.Value>
            }
          />
          <DataList.Item
            label={<DataList.Label>{t("terminal.stop-loss")}</DataList.Label>}
            value={
              <DataList.Value>
                <ModifyOrderButton
                  value={stopLoss ? <NumberFormat value={stopLoss} decimalScale={priceDecimalScale} /> : null}
                >
                  {onClose => (
                    <StopLossPopoverContent
                      onClose={onClose}
                      openPrice={price}
                      orderType={type}
                      orderId={id}
                      priceDecimalScale={priceDecimalScale}
                      stopLoss={stopLoss}
                      takeProfit={takeProfit}
                      ask={ask}
                      bid={bid}
                      accountId={accountId}
                      baseCurrency={baseCurrency}
                      quoteCurrency={quoteCurrency}
                      contractSize={contractSize}
                      volume={volume}
                      currency={currency}
                      currencyDecimalScale={currencyDecimalScale}
                    />
                  )}
                </ModifyOrderButton>
              </DataList.Value>
            }
          />
          <DataList.Item
            label={<DataList.Label>{t("terminal.time")}</DataList.Label>}
            value={<DataList.Value>{terminalFormatDate(date)}</DataList.Value>}
          />
        </DataList>
      }
      footer={
        <CancelPendingOrderButton orderId={id} accountId={accountId}>
          {({ cancelOrder, isLoading }) => (
            <NewButton fullWidth variant="secondary" layout="auto" onClick={cancelOrder} pending={isLoading}>
              {t("terminal.orders.cancel-pending-order")}
            </NewButton>
          )}
        </CancelPendingOrderButton>
      }
    />
  );
};

const Component = memo(PendingOrderCard);

export { Component as PendingOrderCard };
