import type { FC } from "react";

import { Skeleton } from "@/shared/ui";

const AccountDetailsSkeleton: FC = () => (
  <div className="flex items-center gap-3">
    <Skeleton className="h-7 w-11 rounded-[8px]" />
    <div className="flex flex-col gap-2">
      <Skeleton className="h-[18px] w-[125px] rounded-[8px]" />
      <Skeleton className="h-3.5 w-[125px] rounded-[8px]" />
    </div>
  </div>
);

export { AccountDetailsSkeleton };
