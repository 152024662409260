import dayjs from "dayjs";
import type { FC } from "react";
import { memo } from "react";
import { useTranslation } from "react-i18next";

import { type TerminalSymbolWidgetTrend, TradingCentralSignalTrend } from "@/services/openapi";
import { Text } from "@/shared/ui";

import { WidgetBlock } from "../../../widgets/block";
import { SentimentIcon } from "./icon";

type Props = {
  trend: TerminalSymbolWidgetTrend;
};

const SentimentTrendWidget: FC<Props> = ({ trend }) => {
  const { t } = useTranslation();

  const { dateNextCalculation, trend: trendType } = trend;

  return (
    <WidgetBlock
      title={t("terminal.insights.widgets.sentiment-trend.title")}
      description={t("terminal.insights.widgets.sentiment-trend.description")}
    >
      <div className="flex items-center justify-between gap-4">
        <div className="flex flex-col gap-2">
          <Text variant="M Compact / Medium" color="primary">
            {trendType === TradingCentralSignalTrend.Bullish && t("terminal.insights.widgets.sentiment-trend.bullish")}
            {trendType === TradingCentralSignalTrend.Bearish && t("terminal.insights.widgets.sentiment-trend.bearish")}
            {trendType === TradingCentralSignalTrend.Neutral && t("terminal.insights.widgets.sentiment-trend.neutral")}
          </Text>
          <Text variant="S / Regular" color="secondary">
            {t("terminal.insights.widgets.sentiment-trend.next-update", {
              date: dayjs(dateNextCalculation).format("MMM D, HH:mm"),
            })}
          </Text>
        </div>
        <SentimentIcon trend={trendType!} />
      </div>
    </WidgetBlock>
  );
};

const Component = memo(SentimentTrendWidget);

export { Component as SentimentTrendWidget };
