import { useEffect, useMemo } from "react";
import { useInView } from "react-intersection-observer";
import type { InfiniteData } from "react-query";

type Params<K> = {
  data: InfiniteData<K> | undefined;
  fetchNextPage: () => void;
  isFetchingNextPage: boolean;
};

const useInfiniteScrollQuery = <
  T,
  K extends {
    total?: number;
    items?: T[] | null;
  },
>({
  fetchNextPage,
  isFetchingNextPage,
  data,
}: Params<K>) => {
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, isFetchingNextPage]);

  const items = useMemo(() => {
    const items: T[] = [];

    data?.pages.forEach(page => {
      items.push(...page.items!);
    });

    return items;
  }, [data?.pages]);

  return { ref, items };
};

export { useInfiniteScrollQuery };
