import type { FC } from "react";

import { TerminalTableState, useLayoutContext } from "@/features/terminal/layout/context";

import { AccountDetails } from "./account-details/account-details";
import { ChartContainer } from "./chart/container";
import { ChartSymbolsContainer } from "./chart-symbols/container";
import { DepositButtonContainer } from "./deposit/container";
import { MobileSymbolBlock } from "./mobile-symbol/block";
import { MobilePlaceOrderBlock } from "./place-order/mobile/block";
import { TerminalMobileFrame } from "./terminal-mobile.frame";
import { MobileTradingTablesBlock } from "./trading-tables/mobile/block";

const TerminalMobile: FC = () => {
  const { isChartExpanded, table, isMobileSymbolPage } = useLayoutContext();

  return (
    <TerminalMobileFrame
      showFullscreenChart={isChartExpanded && table === TerminalTableState.NONE}
      isMobileSymbolPage={isMobileSymbolPage}
      symbolPageSlot={<MobileSymbolBlock />}
      chartSymbolsSlot={<ChartSymbolsContainer />}
      accountDetailsSlot={<AccountDetails />}
      depositButtonSlot={<DepositButtonContainer />}
      chartSlot={<ChartContainer />}
      placeOrderSlot={<MobilePlaceOrderBlock />}
      tablesSlot={<MobileTradingTablesBlock />}
    />
  );
};

export { TerminalMobile };
