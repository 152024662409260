import { type FC } from "react";

import { Text } from "@/shared/ui";

import { SymbolIcon } from "../../symbols/icon";

type Props = {
  symbol: string;
  name: string;
};

const SymbolInfoIconBlock: FC<Props> = ({ name, symbol }) => {
  return (
    <div className="flex items-center gap-4">
      <SymbolIcon symbol={symbol} size="xxl" />
      <div className="flex flex-col gap-1">
        <Text variant="L / Medium" color="primary">
          {name}
        </Text>
        <Text variant="S / Regular" color="secondary">
          {symbol}
        </Text>
      </div>
    </div>
  );
};

export { SymbolInfoIconBlock };
