import { useTranslation } from "react-i18next";

import { IconWifi } from "@/domains/icons";
import { useOnline } from "@/hooks/online.hook";
import { cn } from "@/shared/styles";
import { Tooltip } from "@/shared/ui";

const NetworkConnection = () => {
  const { t } = useTranslation();

  const isOnline = useOnline();

  return (
    <Tooltip collisionPadding={2} content={isOnline ? t("common.stable-internet") : t("common.no-internet")}>
      <div className={cn("*:size-4", isOnline ? "text-positive-text" : "text-negative-text")}>
        <IconWifi />
      </div>
    </Tooltip>
  );
};

export { NetworkConnection };
