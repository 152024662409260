import * as ToggleGroup from "@radix-ui/react-toggle-group";
import { cva } from "class-variance-authority";

import { IconChevronDown } from "@/domains/icons";
import { useTranslation } from "@/hooks/translator.hook";
import { Text, UnstyledButton } from "@/shared/ui";

import { TerminalTableState, useLayoutContext } from "../../layout/context";
import { terminalLocators } from "../../locators";
import { useOrdersContext } from "../../orders/context";
import { DekstopTradingTablesContainer } from "./container";

const toggleButtonStyles = cva(
  "typography-S-Regular relative inline-flex h-9 items-center justify-center bg-control-bg px-14 text-contrast-primary outline-none transition-colors first:rounded-s-[24px] last:rounded-e-[24px] hover:bg-control-bg-active data-[state=on]:bg-control-bg-active",
)();

const dividerStyles = cva("h-9 w-px bg-control-bg-active")();

const DesktopTradingTablesBlock = () => {
  const { t } = useTranslation();
  const { table, changeTable, toggleTable } = useLayoutContext();
  const { openOrders, pendingOrders } = useOrdersContext();

  return (
    <div className="flex min-h-0 grow flex-col">
      <div className="grid grid-cols-[1fr_auto_1fr] items-center bg-surface-canvas px-4 py-2.5">
        <Text color="primary" variant="L / Medium" as="h2">
          {t("terminal.trades.title")}
        </Text>
        <ToggleGroup.Root
          value={table}
          onValueChange={value => {
            if (!value) {
              changeTable(TerminalTableState.NONE);
              return;
            }
            changeTable(value as TerminalTableState);
          }}
          className="flex"
          type="single"
        >
          <ToggleGroup.Item
            data-test={terminalLocators.tradingTables.tabs.open}
            className={toggleButtonStyles}
            value={TerminalTableState.OPEN}
          >
            {t("terminal.open")}: {openOrders.length}
          </ToggleGroup.Item>
          <div className={dividerStyles} />
          <ToggleGroup.Item
            data-test={terminalLocators.tradingTables.tabs.pending}
            className={toggleButtonStyles}
            value={TerminalTableState.PENDING}
          >
            {t("terminal.pending")}: {pendingOrders.length}
          </ToggleGroup.Item>
          <div className={dividerStyles} />
          <ToggleGroup.Item
            data-test={terminalLocators.tradingTables.tabs.closed}
            className={toggleButtonStyles}
            value={TerminalTableState.CLOSED_POSITIONS}
          >
            {t("terminal.closed-positions")}
          </ToggleGroup.Item>
        </ToggleGroup.Root>
        <UnstyledButton
          className="relative ms-auto grid place-items-center text-contrast-primary after:absolute after:size-11"
          onClick={toggleTable}
        >
          <IconChevronDown className="size-4" />
        </UnstyledButton>
      </div>
      {table !== TerminalTableState.NONE && <DekstopTradingTablesContainer />}
    </div>
  );
};

export { DesktopTradingTablesBlock };
