import dayjs from "dayjs";
import { type FC, useState } from "react";
import { useQueryClient } from "react-query";
import { ScrollRestoration } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { logError } from "@/app/libs/sentry";
import { BonusBanner } from "@/entities/bonuses/banner";
import { getShowBonusBanner } from "@/entities/bonuses/helpers";
import { useClientId } from "@/hooks/client-id.hook";
import type { BonusUserPlatform, TradingAccount } from "@/services/openapi";
import { bonusesQueryKeys } from "@/state/server/bonuses";
import { useDepositMutation } from "@/state/server/payment";

import { useDepositInfo } from "../../deposit-info.hook";
import { AmountStepContainer } from "../../steps/amount/container";
import { CheckStepContainer } from "../../steps/check/container";
import { PaymentMethodStepContainer } from "../../steps/payment-method/container";

type Props = {
  currentAccount: TradingAccount;
  bonuses: BonusUserPlatform[];
};

const DepositOptionsForm: FC<Props> = ({ currentAccount, bonuses }) => {
  const clientId = useClientId();
  const [paymentLink, setPaymentLink] = useState("");

  const bonus = bonuses[0] as BonusUserPlatform | null;

  const queryClient = useQueryClient();

  const [uniqueId] = useState(() => uuidv4());

  const hasBonus = !!bonus;

  const {
    currentStep,
    currentPaymentMethod,
    currentAmount,
    isBonusAccepted,
    backFromAmount,
    backFromCheck,
    nextFromAmount,
    setCurrentPaymentMethod,
    nextFromPaymentMethod,
    setCurrentAmount,
    setIsBonusAccepted,
  } = useDepositInfo();
  const { mutateAsync: deposit, isLoading: submitIsloading } = useDepositMutation();

  const onSubmit = () => {
    deposit(
      {
        accountId: currentAccount.id,
        amount: currentAmount,
        deviceType: "desktop",
        isBonusesRejected: hasBonus ? !isBonusAccepted : void 0,
        paymentMethodId: currentPaymentMethod!.id,
        recurringId: currentPaymentMethod!.recurringId!,
        id: uniqueId,
      },
      {
        onSuccess: res => {
          setPaymentLink(res);
          if (hasBonus && isBonusAccepted) {
            queryClient.invalidateQueries(bonusesQueryKeys.base);
          }

          window.dataLayer?.push({
            event: "gtm_events",
            custom_timestamp: dayjs().valueOf(),
            client_id: clientId,
            ec: "attempt_purch",
            ea: "no_error",
            el: "no_error",
            ga4_event_name: "attempt_purch",
          });

          window.open(res, "_blank");
        },
        onError: ({ code, errorMessage }) => {
          window.dataLayer?.push({
            event: "gtm_events",
            custom_timestamp: dayjs().valueOf(),
            client_id: clientId,
            ec: "attempt_purch",
            ea: "error",
            el: code,
            ga4_event_name: "attempt_purch",
          });

          logError(errorMessage);
        },
      },
    );
  };

  return (
    <>
      <ScrollRestoration key={currentStep} />

      {currentStep === "paymentMethod" && bonus && getShowBonusBanner(bonus, true) && (
        <div className="mb-8">
          <BonusBanner bonus={bonus} showCloseButton={false} />
        </div>
      )}
      {currentStep === "paymentMethod" && (
        <PaymentMethodStepContainer
          next={nextFromPaymentMethod}
          setPaymentMethod={setCurrentPaymentMethod}
          accountId={currentAccount.id!}
        />
      )}

      {currentStep === "amount" && (
        <AmountStepContainer
          currentAmount={currentAmount}
          currentAccount={currentAccount}
          currentPaymentMethod={currentPaymentMethod!}
          setCurrentAmount={setCurrentAmount}
          next={nextFromAmount}
          back={backFromAmount}
        />
      )}

      {currentStep === "check" && (
        <CheckStepContainer
          paymentLink={paymentLink}
          submitIsloading={submitIsloading}
          bonus={bonus}
          currentAmount={currentAmount}
          currentAccount={currentAccount}
          isBonusAccepted={isBonusAccepted}
          currentPaymentMethod={currentPaymentMethod!}
          setIsBonusAccepted={setIsBonusAccepted}
          back={backFromCheck}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};

export { DepositOptionsForm };
