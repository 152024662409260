import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { useLayoutContext } from "@/features/terminal/layout/context";
import { useOrdersContext } from "@/features/terminal/orders/context";
import { useExtendedOrdersContext } from "@/features/terminal/orders/extended-orders.context";
import { useCurrentSymbolContext } from "@/features/terminal/symbol-info/current-symbol-context";

import { TradingTableEmptyState } from "../../ui/empty-state";
import { TradingTable } from "../table";
import { OpenOrdersBodyRow } from "./body-row";
import { OpenOrdersHeaderRow } from "./header-row";

type Props = {
  accountId: string;
  currency: string;
  currencyDecimalScale: number;
  orders: ReturnType<typeof useExtendedOrdersContext>["extendedOpenOrders"];
  setSymbol: ReturnType<typeof useCurrentSymbolContext>["setSymbol"];
  ordersIsLoading: ReturnType<typeof useOrdersContext>["ordersIsLoading"];
  openWatchlist: ReturnType<typeof useLayoutContext>["openWatchlist"];
};

const OpenOrdersTable: FC<Props> = ({
  orders,
  accountId,
  currency,
  setSymbol,
  currencyDecimalScale,
  ordersIsLoading,
  openWatchlist,
}) => {
  const { t } = useTranslation();

  return (
    <TradingTable
      items={orders}
      emptyState={
        <TradingTableEmptyState
          title={t("terminal.empty-state.open-title")}
          description={t("terminal.empty-state.open-description")}
          action={
            <TradingTableEmptyState.Button onClick={() => openWatchlist()}>
              {t("terminal.go-to-watchlist")}
            </TradingTableEmptyState.Button>
          }
        />
      }
      isLoading={ordersIsLoading}
      renderHeader={() => (
        <OpenOrdersHeaderRow
          accountId={accountId}
          currency={currency}
          currencyDecimalScale={currencyDecimalScale}
          ordersCount={orders.length}
        />
      )}
      renderRow={order => (
        <OpenOrdersBodyRow
          key={order.id}
          order={order}
          accountId={accountId}
          currency={currency}
          setSymbol={setSymbol}
          currencyDecimalScale={currencyDecimalScale}
        />
      )}
    />
  );
};

export { OpenOrdersTable };
