import dayjs from "dayjs";
import Calendar from "dayjs/plugin/calendar";
import isBetween from "dayjs/plugin/isBetween";
import isToday from "dayjs/plugin/isToday";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import type { DayOfWeek } from "@/services/openapi";

dayjs.extend(utc);
dayjs.extend(Calendar);
dayjs.extend(isBetween);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(isToday);

export const convertDateToISOString = (date: dayjs.ConfigType, toDate?: boolean) => {
  if (!date) {
    return null;
  }

  const dateString = dayjs(date).startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

  if (toDate) {
    return new Date(dateString);
  }

  return dateString;
};

export const isYoungerThan = (date: dayjs.ConfigType, minAge: number) => {
  const minDateOfBirth = dayjs().subtract(minAge, "year");
  return dayjs(date).isAfter(minDateOfBirth);
};

export const isTooOld = (date: dayjs.ConfigType) => {
  return dayjs(date).isAfter("1900-01-01");
};

const dayMappings: Record<DayOfWeek, number> = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7,
};

const getDayOfWeek = (day: DayOfWeek) => {
  return dayMappings[day];
};

export { getDayOfWeek, dayMappings };
