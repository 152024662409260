import type { FC } from "react";
import { memo } from "react";
import { useTranslation } from "react-i18next";

import { useInfiniteScrollQuery } from "@/app/hooks";
import type { TerminalTradingCentralArticle, TerminalTradingCentralArticleItemsContainer } from "@/services/openapi";
import { useInfiniteTradingCentralNewsQuery } from "@/state/server/terminal";

import { InsightsLoader } from "../ui/loader";
import { WidgetBlock } from "../widgets/block";
import { Article } from "./article";

type Props = {
  symbol: string;
};

const _NewsContainer: FC<Props> = ({ symbol }) => {
  const { t } = useTranslation();

  const { fetchNextPage, hasNextPage, data, isFetchingNextPage, isLoading } = useInfiniteTradingCentralNewsQuery({
    pageSize: 20,
    symbol,
  });

  const { ref, items } = useInfiniteScrollQuery<
    TerminalTradingCentralArticle,
    TerminalTradingCentralArticleItemsContainer
  >({ data, fetchNextPage, isFetchingNextPage });

  return (
    <WidgetBlock hasScrollButton title={t("terminal.insights.widgets.news-feed.title")}>
      {isLoading && <InsightsLoader />}
      <div className="flex flex-col gap-10">
        {items.map(article => (
          <Article key={article.id} article={article} />
        ))}
      </div>
      {hasNextPage ? <InsightsLoader ref={ref} /> : null}
    </WidgetBlock>
  );
};

const Component = memo(_NewsContainer);

export { Component as NewsContainer };
