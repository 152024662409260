import { type FC, type ReactNode } from "react";

import { NumberFormat } from "@/app/components";
import { cn } from "@/shared/styles";
import { IconPriceChangeArrowDown, IconPriceChangeArrowUp } from "@/shared/ui/icons";

import type { PriceChange } from "../../helpers/formulas";

type Props = {
  priceChange: PriceChange;
  children: ({ icon, value }: { icon?: ReactNode; value: ReactNode }) => ReactNode;
};

const SymbolPriceChange: FC<Props> = ({ priceChange, children }) => {
  const value = <NumberFormat value={priceChange.priceChange} decimalScale={2} suffix="%" />;

  const icon =
    priceChange.direction !== 0 ? (
      <div className={cn("*:size-4", priceChange.direction === 1 ? "text-positive-text" : "text-negative-text")}>
        {priceChange.direction === 1 && <IconPriceChangeArrowUp />}
        {priceChange.direction === -1 && <IconPriceChangeArrowDown />}
      </div>
    ) : null;

  return children({ value, icon }) as JSX.Element;
};

export { SymbolPriceChange };
