import type { IChartingLibraryWidget } from "public/charting_library/charting_library";

const setAutoPricescale = (widget: IChartingLibraryWidget) => {
  const pane = widget.activeChart().getPanes()[0];
  if (pane) {
    const priceScale = pane.getMainSourcePriceScale();
    priceScale?.setAutoScale(true);
  }
};

export { setAutoPricescale };
