import { type FC, memo } from "react";

import { terminalLocators } from "@/features/terminal/locators";
import { useTranslation } from "@/hooks/translator.hook";
import { NewButton } from "@/shared/ui";

import { CloseAllOpenOrdersButton } from "../../open-orders/close-all/button";
import { TradingTable } from "../table";

type Props = {
  ordersCount: number;
  accountId: string;
  currency: string;
  currencyDecimalScale: number;
};

const OpenOrdersHeaderRow: FC<Props> = ({ ordersCount, currency, accountId, currencyDecimalScale }) => {
  const { t } = useTranslation();

  return (
    <TradingTable.Row showBorder>
      <TradingTable.Head>{t("terminal.symbol")}</TradingTable.Head>
      <TradingTable.Head>{t("common.type")}</TradingTable.Head>
      <TradingTable.Head>{t("terminal.volume-lot")}</TradingTable.Head>
      <TradingTable.Head>{t("terminal.margin-currency", { currency })}</TradingTable.Head>
      <TradingTable.Head>{t("terminal.open-price")}</TradingTable.Head>
      <TradingTable.Head>{t("terminal.current-price")}</TradingTable.Head>
      <TradingTable.Head>{t("terminal.take-profit")}</TradingTable.Head>
      <TradingTable.Head>{t("terminal.stop-loss")}</TradingTable.Head>
      <TradingTable.Head>{t("terminal.open-time")}</TradingTable.Head>
      <TradingTable.Head align="end">
        {t("terminal.swap")}, {currency}
      </TradingTable.Head>
      <TradingTable.Head align="end">{t("terminal.pnl-currency", { currency })}</TradingTable.Head>
      <TradingTable.Head align="end">
        {ordersCount > 1 && (
          <CloseAllOpenOrdersButton
            accountId={accountId}
            currency={currency}
            currencyDecimalScale={currencyDecimalScale}
          >
            {opened => (
              <NewButton
                layout="auto"
                checked={opened}
                variant="flat"
                size="sm"
                data-test={terminalLocators.tradingTables.open.closeAll}
              >
                {t("terminal.orders.close-all.name")}
              </NewButton>
            )}
          </CloseAllOpenOrdersButton>
        )}
      </TradingTable.Head>
    </TradingTable.Row>
  );
};

const Component = memo(OpenOrdersHeaderRow);

export { Component as OpenOrdersHeaderRow };
