import { type FC, Fragment } from "react";

import { useSignalContext } from "../../signal/context";
import { useCurrentSymbolContext } from "../../symbol-info/current-symbol-context";
import { PlaceOrderContainer } from "../container";
import { TerminalPlaceOrderProvider } from "../context";
import { TradingUnavailableBlock } from "../trading-unavailable/block";
import { PlaceOrderSignalBlock } from "./signal-block";
import { MobilePlaceOrderTabs } from "./tabs";

const MobilePlaceOrderBlock: FC = () => {
  const { currentSymbol, isTradingAvailable } = useCurrentSymbolContext();

  const { signalFormOpened } = useSignalContext();

  return (
    <Fragment key={currentSymbol}>
      {isTradingAvailable ? (
        <>
          {signalFormOpened ? (
            <PlaceOrderSignalBlock />
          ) : (
            <TerminalPlaceOrderProvider>
              <MobilePlaceOrderTabs>
                <PlaceOrderContainer />
              </MobilePlaceOrderTabs>
            </TerminalPlaceOrderProvider>
          )}
        </>
      ) : (
        <MobilePlaceOrderTabs>
          <TradingUnavailableBlock />
        </MobilePlaceOrderTabs>
      )}
    </Fragment>
  );
};

export { MobilePlaceOrderBlock };
