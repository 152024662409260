import { type FC, type ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";

import { Text, ToggleGroup } from "@/shared/ui";

import { useLayoutContext } from "../../layout/context";
import { useCurrentSymbolContext } from "../../symbol-info/current-symbol-context";
import { SymbolInfoDialogButton } from "../../symbol-info/dialog/button";

type Props = {
  children?: ReactNode;
};

const MobilePlaceOrderTabs: FC<Props> = ({ children }) => {
  const { t } = useTranslation();

  const [tab, setTab] = useState<"trade" | "insights">("trade");

  const { openInsightsDialog } = useLayoutContext();

  const { symbolInfo } = useCurrentSymbolContext();

  return (
    <>
      <div className="mb-3 flex flex-col gap-1 px-4">
        <div className="flex items-center justify-between gap-2">
          <Text as="h3" color="primary" variant="M / Medium">
            {symbolInfo.name}
          </Text>
          <SymbolInfoDialogButton />
        </div>
        <ToggleGroup
          value={tab}
          onValueChange={tab => {
            if (tab === "insights") {
              openInsightsDialog();
              return;
            }
            setTab(tab);
          }}
        >
          <ToggleGroup.Item value="trade">{t("terminal.place-order.tabs.trade")}</ToggleGroup.Item>
          <ToggleGroup.Item value="insights">{t("terminal.place-order.tabs.insights")}</ToggleGroup.Item>
        </ToggleGroup>
      </div>
      {children}
    </>
  );
};

export { MobilePlaceOrderTabs };
