import { type ComponentProps, type FC, useCallback } from "react";

import { useSymbolsContext } from "@/features/terminal/contexts/symbols.context";
import { getInputNumberValue } from "@/features/terminal/helpers/formatting";
import { TerminalCloseOrderType, TerminalDealType } from "@/services/openapi";
import { useCloseTerminalOrdersMutation } from "@/state/server/terminal";

import { PartialCloseForm } from "./form";

type Props = {
  accountId: string;
  orderId: number;
  onClose: () => void;
  volume: number;
  volumeDecimalScale: number;
  volumeStep: number;
  volumeMin: number;
  currency: string;
  currentPrice: number;
  openPrice: number;
  contractSize: number;
  orderType: TerminalDealType;
  baseCurrency: string;
  quoteCurrency: string;
  currencyDecimalScale: number;
  profitRate: number | undefined;
};

const PartialCloseContainer: FC<Props> = ({
  accountId,
  orderId,
  onClose,
  volume,
  volumeDecimalScale,
  volumeMin,
  volumeStep,
  currency,
  contractSize,
  currentPrice,
  openPrice,
  orderType,
  baseCurrency,
  quoteCurrency,
  currencyDecimalScale,
  profitRate,
}) => {
  const { mutateAsync: closeOrder } = useCloseTerminalOrdersMutation();

  const { symbols } = useSymbolsContext();

  const handleSubmit: ComponentProps<typeof PartialCloseForm>["onSubmit"] = useCallback(
    ({ volume }) => {
      return closeOrder(
        {
          tradingAccountId: accountId,
          terminalCloseOrderRequest: {
            id: orderId,
            volume: getInputNumberValue(volume),
            type: TerminalCloseOrderType.Market,
          },
        },
        {
          onSuccess: () => {
            onClose();
          },
        },
      );
    },
    [closeOrder, accountId, orderId, onClose],
  );

  return (
    <PartialCloseForm
      onSubmit={handleSubmit}
      volume={volume}
      volumeStep={volumeStep}
      volumeMin={volumeMin}
      volumeDecimalScale={volumeDecimalScale}
      currency={currency}
      contractSize={contractSize}
      currentPrice={currentPrice}
      openPrice={openPrice}
      orderType={orderType}
      baseCurrency={baseCurrency}
      quoteCurrency={quoteCurrency}
      currencyDecimalScale={currencyDecimalScale}
      profitRate={profitRate}
      symbols={symbols}
    />
  );
};

export { PartialCloseContainer };
