import { useInfiniteScrollQuery } from "@/app/hooks";
import { TerminalOrderSorting, type TerminalPosition, type TerminalPositionItemsContainer } from "@/services/openapi";
import { useInfiniteTerminalPositionsQuery } from "@/state/server/terminal";

const useLoadClosedPositions = ({ accountId }: { accountId: string }) => {
  const { fetchNextPage, hasNextPage, data, isFetchingNextPage, isLoading } = useInfiniteTerminalPositionsQuery({
    pageSize: 40,
    tradingAccountId: accountId,
    sorting: TerminalOrderSorting.ByDateCloseDesc,
  });

  const { ref, items } = useInfiniteScrollQuery<TerminalPosition, TerminalPositionItemsContainer>({
    fetchNextPage,
    isFetchingNextPage,
    data,
  });

  return { ref, hasNextPage, isLoading, items };
};

export { useLoadClosedPositions };
